import React from "react";

interface PropsInterface {
  columns: any[];
  dataSource: any[];
}

export const StatementComponent: React.FC<PropsInterface> = (props) => {
  const { columns = [], dataSource = [] } = props;

  const hiddenColumnsMobile = (key: string) =>
    ([
      "billingPeriod",
      "paymentDueDate",
      "invoiceAmount",
      "remainingBalance",
      "invoiceStatus",
    ].includes(key) &&
      "d-md-down-none") ||
    "";

  return (
    <div className="flex-grow-1 justify-content-center">
      <div className="card">
        <header className="card-header" style={{ border: 0 }}>
          <h2>Statements</h2>
        </header>

        {/* Mobile */}
        <table className="table table-striped table-hover d-lg-none">
          <tbody>
            {dataSource.map((data: any, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    {columns.map((column: any, columnIdx) => {
                      const render = column.render(
                        data[column.key],
                        data,
                        columnIdx
                      );
                      return (
                        render && (
                          <div key={column.key} style={{ padding: 10 }}>
                            <div>
                              <strong>{column.title}</strong>
                            </div>
                            <div>{render}</div>
                          </div>
                        )
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Desktop */}
        <table className="table table-striped table-hover d-md-down-none">
          <thead>
            <tr>
              {columns.map((column, key: number) => {
                return (
                  <th
                    scope="col"
                    key={`col-${key}`}
                    className={hiddenColumnsMobile(column.key)}
                  >
                    {column.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dataSource.map((data: any, idx) => {
              return (
                <tr key={idx}>
                  {columns.map((column: any, columnIdx) => {
                    return column.render ? (
                      <td
                        key={columnIdx}
                        className={hiddenColumnsMobile(column.key)}
                      >
                        {column.render(data[column.key], data, columnIdx)}
                      </td>
                    ) : null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
