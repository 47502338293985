import { PAYMENT_TYPE } from "./utils";
import CIcon from "@coreui/icons-react";
export const COLUMNS = [
  {
    key: "display",
    title: "Payment Source",
    render: (display: any) => display,
  },
  {
    key: "type",
    title: "Type",
    render: (type: string, data: any) => {
      if (type === "CREDIT_CARD") {
        let cardType = Object.values(PAYMENT_TYPE).filter(
          (paymentType) =>
            type === "CREDIT_CARD" &&
            paymentType.KEY ===
              data.providerName.toUpperCase().replace(" ", "_")
        )?.[0];
        if (cardType == null) cardType = PAYMENT_TYPE.CARD;

        return (
          <span style={{ textTransform: "capitalize" }}>
            <img
              src={cardType.LOGO}
              alt={cardType.KEY}
              style={{ height: 28, margin: "-4px 6px 0 0" }}
            />
            {type?.toLowerCase().replace("_", " ")}
          </span>
        );
      }

      const bank = Object.values(PAYMENT_TYPE).filter(
        (paymentType) => paymentType.KEY === type
      )?.[0];
      if (bank == null) return null;
      return (
        <span style={{ textTransform: "capitalize" }}>
          <CIcon
            name={bank?.LOGO}
            alt={bank?.KEY}
            size={"2xl"}
            style={{ margin: "-4px 6px 0 0" }}
          />
          {type?.toLowerCase()}
        </span>
      );
    },
  },
];
