import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { AutoPayDebitDetails } from "./AutoPayDebitDetails";
import { useAppSelector } from "../../../store/hooks";

interface PropsInterface {
  paymentMethodDisplay?: string;
  resolve: any;
  reject: any;
}

const AutoPayConfirmModal: React.FC<PropsInterface> = (props) => {
  const { paymentMethodDisplay, resolve, reject } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const accountSummary = useAppSelector((state) => state.accountSummary);
  const { data, activeTenantAccountIndex } = accountSummary;
  const accountSummaryData = data?.[activeTenantAccountIndex];
  const isAutopayEnabled = accountSummaryData?.autopay?.enabled;

  return (
    <CModal show onClose={reject}>
      <CModalHeader>
        <strong>Confirm AutoPay Preference</strong>
      </CModalHeader>
      <CModalBody>
        {!isAutopayEnabled && (
          <>
            <p>
              By enabling AutoPay, I authorize King Energy to electronically
              debit{" "}
              <AutoPayDebitDetails
                paymentMethodDisplay={paymentMethodDisplay}
              />
              , and, if necessary, electronically credit my account to correct
              erroneous debits.
            </p>
            <p>
              I understand that subsequent debts of differing amounts will be
              automatically debited.
            </p>
          </>
        )}
        <p>
          Are you sure you want to {isAutopayEnabled ? "disable" : "enable"}{" "}
          AutoPay?
        </p>
      </CModalBody>
      <CModalFooter style={{ display: "flex" }}>
        <CButton
          color={isAutopayEnabled ? "danger" : "primary"}
          style={{ flex: 2 }}
          disabled={isSubmitting}
          onClick={() => {
            setSubmitting(true);
            resolve();
          }}
        >
          {isAutopayEnabled ? "Disable" : "Enable"} AutoPay
        </CButton>
        <CButton color="secondary" style={{ flex: 1 }} onClick={reject}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AutoPayConfirmModal;
