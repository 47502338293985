import axios, { AxiosResponse } from "axios";
import { trackEvent } from "../utils/analytics";
import { AccountSummaryData } from "../AccountSummary.slice";
import { PaymentMethod } from "../features/PaymentMethods/PaymentMethods.slice";
import { DocumentsResponse } from "../features/Documents/Documents.slice";

const BASE_URL = "/api/v1/billing";
const ACCOUNT_URL = `${BASE_URL}/account`;
const PAYMENT_METHOD_URL = `${BASE_URL}/paymentMethod`;
const PAYMENT_URL = `${BASE_URL}/payment`;
const INVOICE_URL = `${BASE_URL}/invoice`;

export const getStatementSummaryApi = () =>
  Promise.resolve({
    dueDate: "Mar 15",
    amount: 1023.43,
    startDate: "Feb 1",
    endDate: "Feb 28",
    autoPayDescription:
      "Debit on or around Mar 15 from account ending in x1234",
  });

export const getStatementHistoryApi = (tenantAccountId: string) => {
  trackEvent({ action: "API > Statement History" });
  return axios
    .get(`${INVOICE_URL}/history`, { params: { tenantAccountId } })
    .then((res) => res.data);
};

export const getDocumentsApi = (
  tenantAccountId: string
): Promise<DocumentsResponse> => {
  trackEvent({ action: "API > Documents" });
  return axios
    .get(`${BASE_URL}/document`, { params: { tenantAccountId } })
    .then((res) => res.data as DocumentsResponse);
};

export const getStatementApi = (link: string) => {
  return axios
    .get(`${BASE_URL}${link}`, { responseType: "arraybuffer" })
    .then((res) =>
      window.URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      )
    );
};

export const getDocumentApi = (link: string, tenantAccountId: string) => {
  return axios
    .get(`${BASE_URL}${link}`, {
      params: {
        tenantAccountId,
        cacheBuster: Date.now(),
      },
      responseType: "arraybuffer",
    })
    .then((res) =>
      window.URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      )
    );
};

export const getPaymentMethodsApi = (): Promise<PaymentMethod[]> => {
  return axios
    .get(PAYMENT_METHOD_URL)
    .then((res) => res.data as PaymentMethod[]);
};

export const createPaymentMethodApi = (payload: object) => {
  trackEvent({ action: "API > Payment Methods > Create" });
  return axios.post(PAYMENT_METHOD_URL, payload).then((res) => res.data);
};

export const deletePaymentMethodApi = (paymentMethodId: string) => {
  trackEvent({ action: "API > Payment Methods > Delete" });
  return axios
    .delete(`${PAYMENT_METHOD_URL}/${paymentMethodId}`)
    .then((res) => res.data);
};

export const setDefaultPaymentMethodApi = (paymentMethodId: string) => {
  trackEvent({ action: "API > Payment Methods > Set Primary" });
  return axios
    .patch(`${PAYMENT_METHOD_URL}/${paymentMethodId}/default`, {
      primary: true,
    })
    .then((res) => res.data);
};

export const getAccountSummaryApi = (): Promise<AccountSummaryData[]> => {
  return axios
    .get(ACCOUNT_URL)
    .then((res: AxiosResponse) => res.data as AccountSummaryData[]);
};

export const getPreferenceApi = (
  tenantAccountId: string,
  preferenceType: string
) => {
  trackEvent({
    action: "API > Preference > Get",
    label: preferenceType,
  });
  return axios
    .get(`${ACCOUNT_URL}/${tenantAccountId}/preference/${preferenceType}`)
    .then((res) => res.data);
};

export const setPreferenceApi = (
  tenantAccountId: string,
  preferenceType: string,
  payload: object
) => {
  trackEvent({
    action: "API > Preference > Set",
    label: preferenceType,
  });
  return axios
    .patch(
      `${ACCOUNT_URL}/${tenantAccountId}/preference/${preferenceType}`,
      payload
    )
    .then((res) => res.data);
};

export const setPaymentPreferenceApi = (
  tenantAccountId: string,
  preferenceType: string,
  enabled: boolean,
  paymentMethodId?: string
) => {
  trackEvent({
    action: "API > Preference > Set",
    label: preferenceType,
    value: String(enabled),
  });
  return axios
    .patch(`${ACCOUNT_URL}/${tenantAccountId}/preference/${preferenceType}`, {
      enabled,
      paymentMethodId,
    })
    .then((res) => res.data);
};

export const sendPaymentApi = (
  tenantAccountId: string,
  invoiceId: string,
  payload: object
) => {
  trackEvent({ action: "API > Payment > Send" });
  return axios
    .post(
      `${ACCOUNT_URL}/${tenantAccountId}/invoice/${invoiceId}/payment`,
      payload
    )
    .then((res) => res.data);
};

export const deletePaymentApi = (paymentId: string) => {
  trackEvent({ action: "API > Payment > Delete" });
  return axios
    .delete(`${BASE_URL}/payment/${paymentId}/cancel`)
    .then((res) => res.data);
};

export const getPaymentHistoryApi = (tenantAccountId: string) => {
  return axios
    .get(`${PAYMENT_URL}/history`, { params: { tenantAccountId } })
    .then((res) => res.data);
};

export const getUserProfileApi = () => {
  return axios.get(`${BASE_URL}/user/profile`).then((res) => res.data);
};

export const setUserProfileApi = (payload: object) => {
  return axios
    .post(`${BASE_URL}/user/profile`, payload)
    .then((res) => res.data);
};
