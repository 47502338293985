import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { trackEvent } from "../utils/analytics";

const { DEV: isDev } = import.meta.env;

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  Sentry.captureException(error, {
    tags: {
      errorMessageDisplayed: true,
    },
  });
  trackEvent({
    action: "Error Boundary",
    label: "Error message displayed to user",
    value: error instanceof Error
      ? error?.message
      : null
  });
  return (
    <div>
      <h4>An unexpected error ocurred</h4>
      This event has been logged. Thanks for your patience while we investigate
      this issue.
      {isDev && error instanceof Error ? (
        <div>Error: {error?.message}</div>
      ) : null}
    </div>
  );
}

export default ErrorBoundary;
