import React, { useCallback, useEffect, useState } from "react";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./scss/style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Toaster from "./features/Toaster/Toaster";
import qs from "qs";
import LinkExpired from "./errors/LinkExpired";
import AccountLinkingFailed from "./errors/AccountLinkingFailed";
import PasswordExpired from "./errors/PasswordExpired";
import { setPersistentValues, trackEvent } from "./utils/analytics";
import { UserData, setUser } from "./User.slice";
import { setActiveTenantAccountId } from "./AccountSummary.slice";
import {
  TheSidebar,
  TheFooter,
  TheHeader,
  TheContent,
} from "./containers/index";
import UserProfileModal from "./features/Profile/components/ContactDetailsModal";
import * as Sentry from "@sentry/react";
import { useAppDispatch } from "./store/hooks";

const { VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE, VITE_AUTH0_SCOPE: AUTH0_SCOPE } =
  import.meta.env;

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const [isLinking, setLinking] = useState(false);
  const [linkingFailure, setLinkingFailure] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  // const [ testTenantAccountGroupId, setTestTenantAccountGroupId ] = useState<any>(null); // For testing
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const {
    getAccessTokenSilently,
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const {
    invitationCode,
    error: auth0QueryParamError,
    message: auth0QueryParamMessage,
  } = qs.parse(search, { ignoreQueryPrefix: true });
  const isImpersonating = window.location !== window.parent.location; // App is loaded in an iframe
  const [isImpersonationWaiting, setImpersonationWaiting] =
    useState(isImpersonating);
  const dispatch = useAppDispatch();

  const tenantAccountGroupId = user?.[
    "https://account.kingenergy.com/tenantAccountGroupId"
  ] as string;
  const case1notLinked =
    isAuthenticated && !invitationCode && !tenantAccountGroupId;
  const case2linking =
    isAuthenticated && Boolean(invitationCode) && !tenantAccountGroupId;
  const case3previouslyLinked =
    isAuthenticated && Boolean(tenantAccountGroupId);

  useEffect(() => {
    if (!isImpersonating || hasToken) {
      return;
    }
    window.addEventListener(
      "message",
      (e: {
        data: {
          tenantAccountCuid: string;
          email: string;
          atk: string;
        };
        origin: string;
      }) => {
        const {
          data: { tenantAccountCuid, email, atk },
          origin,
        }: {
          data: {
            tenantAccountCuid: string;
            email: string;
            atk: string;
          };
          origin: string;
        } = e;
        if (atk == null) {
          return;
        }
        const acceptedOrigins = [
          "https://localhost:3000",
          "https://localhost:3001",
          "https://dev.admin.kingenergy.com",
          "https://admin.kingenergy.com",
        ];
        if (!acceptedOrigins.includes(origin)) {
          throw new Error("Impersonation Error: Origin not accepted");
        }
        try {
          const isJwt = JSON.parse(atob(atk?.split(".")?.[0]))?.typ === "JWT";
          if (!isJwt) {
            throw new Error(
              "Impersonation Error: Message does not contain a JWT"
            );
          }
        } catch {
          throw new Error(
            "Impersonation Error: Message does not contain a JWT"
          );
        }
        dispatch(
          setUser({
            email,
            nickname: email,
          })
        );
        if (!window.location.toString().includes("localhost")) {
          dispatch(setActiveTenantAccountId(tenantAccountCuid)).catch(
            (e: string) => {
              throw new Error(e);
            }
          );
        }
        axios.defaults.headers.common.Authorization = `Bearer ${atk}`;
        setHasToken(true);
        setImpersonationWaiting(false);
        navigate("/dashboard", { replace: true });
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImpersonating]);

  const getAccessTokenSilentlyCB = useCallback(async () => {
    const accessToken = import.meta.env.PROD
      ? await getAccessTokenSilently({
          cacheMode: "off",
          authorizationParams: {
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPE,
          },
        })
      : "token for local env...";
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setHasToken(true);
    if (Boolean(tenantAccountGroupId) === true) {
      navigate("/dashboard", { replace: true });
    }
    return accessToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user]);

  // <For Testing>
  // const userTest: any = {
  //   email: "test@kingenergy.com",
  //   email_verified: false,
  //   name: "test@kingenergy.com",
  //   nickname: "test",
  //   picture: "https://s.gravatar.com/avatar/558da2e1379f15b70cb4e3b6d8a5d4eb?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fjo.png",
  //   sub: "auth0|60fb19477dfdbc0071ba8622",
  //   // "https://account.kingenergy.com/tenantAccountGroupId": "ckrgpp9hb0000elo27tun7li6",
  // };
  // if (testTenantAccountGroupId) {
  //   userTest["https://account.kingenergy.com/tenantAccountGroupId"] = testTenantAccountGroupId;
  // }
  // const tenantAccountGroupId = userTest && userTest["https://account.kingenergy.com/tenantAccountGroupId"];
  // </For Testing>

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // console.log("Case 4 - Not Authenticated");
      if (pathname === "/register") {
        trackEvent({ action: "Auth > Register > Start" });
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
            audience: AUTH0_AUDIENCE,
            scope: "openid profile email",
            redirect_uri: `${window.location.origin}/?invitationCode=${
              invitationCode as string
            }`,
          },
        }).catch((e: string) => {
          throw new Error(e);
        });
      } else if (!auth0QueryParamError && !auth0QueryParamMessage) {
        trackEvent({ action: "Auth > Login > Start" });
        loginWithRedirect().catch((e: string) => {
          throw new Error(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  useEffect((): void => {
    if (isLinking || isLoading) {
      return;
    }
    (async () => {
      try {
        await getAccessTokenSilentlyCB();
        // console.log("Access Token Init", accessTokenInit);
      } catch (e) {
        console.error("Access Token Error - Init", e);
      }

      // console.log("metadata", {
      //   // user,
      //   userTest, // For testing
      //   invitationCode,
      //   tenantAccountGroupId,
      //   isAuthenticated,
      //   isLoading,
      //   case1_NotLinked,
      //   case2_Linking,
      //   case3_PreviouslyLinked
      // });

      setPersistentValues({
        tenantAccountGroupId,
        name: user?.name,
        email: user?.email,
      });

      if (case1notLinked) {
        // console.log("Case 1 - Not Linked");
        trackEvent({ action: "Auth > Error > Not Linked" });
        // setNotLinked(true);
      }

      if (case2linking) {
        // console.log("Case 2 - Linking");
        trackEvent({ action: "Auth > Link > Start" });
        setLinking(true);

        try {
          await getAccessTokenSilentlyCB();
          await axios.post("/api/v1/billing/auth/link", {
            inviteToken: invitationCode,
          });
          trackEvent({ action: "Auth > Link > Success" });
          // setTestTenantAccountGroupId("ckrgpp9hb0000elo27tun7li6"); // For testing
        } catch (e) {
          console.error("Linking error", e);
          trackEvent({
            action: "Auth > Link > Error",
            category: "error",
            value: e as string,
          });
          setLinkingFailure(true);
          return;
        }

        try {
          await getAccessTokenSilentlyCB();
          // console.log("Access Token Post-Link", accessTokenPostLink);
          setLinking(false);
          dispatch(setUser(user as unknown as UserData));
        } catch (e) {
          console.error("Access Token Error - Post Link", e);
          setLinking(false);
          setLinkingFailure(true);
        }
      }

      if (case3previouslyLinked) {
        // console.log("Case 3 - Previously Linked");
        trackEvent({ action: "Auth > Previously Linked" });
        dispatch(setUser(user as unknown as UserData));
        Sentry.setUser({
          email: user?.email,
          tenantAccountGroupId,
        });
        Sentry.setContext("User", {
          email: user?.email,
          tenantAccountGroupId,
        });
        navigate("/dashboard", { replace: true });
      }
    })().catch((e: string) => {
      throw new Error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantAccountGroupId,
    case1notLinked,
    case2linking,
    case3previouslyLinked,
  ]);

  if (auth0QueryParamError) {
    trackEvent({ action: "Auth > Password Expired" });
    return <PasswordExpired />;
  }

  if (auth0QueryParamMessage) {
    trackEvent({ action: "Auth > Link Expired" });
    return <LinkExpired />;
  }

  // if (isNotLinked) {
  //   return <AccountNotLinked />;
  // }

  if (linkingFailure) {
    return <AccountLinkingFailed />;
  }

  if (isLinking) {
    return <div style={{ margin: 20 }}>Linking your account...</div>;
  }

  if ((isLoading && !isImpersonating) || isImpersonationWaiting || !hasToken) {
    return <div style={{ margin: 20 }}>Loading...</div>;
  }

  if (isAuthenticated || hasToken) {
    return (
      <React.Suspense fallback={loading}>
        <div className="c-app c-default-layout">
          <TheSidebar />
          <div className="c-wrapper">
            <TheHeader />
            <div className="c-body">
              <TheContent>
                <Outlet />
              </TheContent>
            </div>
            <TheFooter />
          </div>
          <UserProfileModal />
        </div>
        <Toaster />
        <ScrollRestoration />
      </React.Suspense>
    );
  }

  return null;
};

export default App;
