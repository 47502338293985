import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  deletePaymentMethodApi,
  setDefaultPaymentMethodApi,
} from "../../../apis/apis";
import { refreshPaymentMethods } from "../PaymentMethods.slice";
import { refreshAccountSummary } from "../../../AccountSummary.slice";
import { pushToast } from "../../Toaster/Toaster.slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

interface PropsInterface {
  columns: any;
  dataSource: any;
}

export const PaymentMethodsTable: React.FC<PropsInterface> = (props) => {
  const [removeConfirm, setRemoveConfirm]: any = useState(false);
  const [primaryConfirm, setPrimaryConfirm]: any = useState(false);
  const dispatch = useAppDispatch();
  const { columns = [], dataSource = [] } = props;

  const { data, activeTenantAccountIndex } = useAppSelector(
    (state) => state.accountSummary
  );
  const accountSummaryData = data?.[activeTenantAccountIndex];
  const isAutopayEnabled = accountSummaryData?.autopay?.enabled;
  const autopayPaymentMethodId = accountSummaryData?.autopay?.paymentMethodId;

  const toggleRemoveConfirm = (data?: any) => {
    setRemoveConfirm(data || false);
  };

  const handleRemoveConfirm = async () => {
    try {
      await deletePaymentMethodApi(removeConfirm.id);
      dispatch(
        pushToast({
          message: "Payment method successfully removed",
          type: "success",
        })
      ).catch((e: string) => {
        throw new Error(e);
      });
      dispatch(refreshPaymentMethods()).catch((e: string) => {
        throw new Error(e);
      });
      dispatch(refreshAccountSummary()).catch((e: string) => {
        throw new Error(e);
      });
    } catch (e: any) {
      console.error(e);
      dispatch(
        pushToast({
          message: `${
            e.response?.data?.message
              ? `${e.response?.data?.message}:`
              : "Error:"
          } ${e.message}`,
          type: "error",
        })
      ).catch((e: string) => {
        throw new Error(e);
      });
    }
    setRemoveConfirm(false);
  };

  const togglePrimaryConfirm = (data?: any) => {
    setPrimaryConfirm(data || false);
  };

  const handlePrimaryConfirm = async () => {
    try {
      await setDefaultPaymentMethodApi(primaryConfirm.id);
      dispatch(
        pushToast({
          message: "Primary payment method successfully set",
          type: "success",
        })
      ).catch((e: string) => {
        throw new Error(e);
      });
      dispatch(refreshPaymentMethods()).catch((e: string) => {
        throw new Error(e);
      });
      dispatch(refreshAccountSummary()).catch((e: string) => {
        throw new Error(e);
      });
    } catch (e: any) {
      console.error(e);
      dispatch(
        pushToast({
          message: `${
            e.response?.data?.message
              ? `${e.response?.data?.message}:`
              : "Error:"
          } ${e.message}`,
          type: "error",
        })
      ).catch((e: string) => {
        throw new Error(e);
      });
    }
    setPrimaryConfirm(false);
  };

  if (dataSource.length === 0) {
    return <div className="card-body">No payment methods found</div>;
  }

  const hiddenColumnsMobile = (key: any) =>
    (["status", "type"].includes(key) && "d-md-down-none") || "";

  return (
    <>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            {columns.map((column: any) => {
              return (
                <th
                  scope="col"
                  key={column.key}
                  className={hiddenColumnsMobile(column.key)}
                >
                  {column.title}
                </th>
              );
            })}
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {dataSource.map((data: any, idx: number) => {
            return (
              <tr key={idx}>
                {columns.map((column: any, columnIdx: number) => {
                  if (column.render) {
                    return (
                      <td
                        key={columnIdx}
                        className={hiddenColumnsMobile(column.key)}
                      >
                        {column.render(data[column.key], data, columnIdx)}
                      </td>
                    );
                  }
                  if (column.key in data) {
                    return <td key={column.key}>{data[column.key]}</td>;
                  }
                  return null;
                })}
                <td style={{ textAlign: "center" }}>
                  {data.default ? (
                    <span>
                      <CIcon
                        name="cis-check-circle"
                        alt="Active"
                        style={{ margin: "-4px 6px 0 0" }}
                      />
                      Primary
                    </span>
                  ) : (
                    <CButton onClick={() => togglePrimaryConfirm(data)}>
                      Make Primary
                    </CButton>
                  )}
                </td>
                <td>
                  <CButton onClick={() => toggleRemoveConfirm(data)}>
                    Remove
                  </CButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <CModal show={!!removeConfirm} onClose={() => toggleRemoveConfirm(false)}>
        <CModalHeader>
          <strong>Confirm payment method removal</strong>
        </CModalHeader>
        <CModalBody>
          {isAutopayEnabled && autopayPaymentMethodId === removeConfirm.id && (
            <CAlert color="warning">
              <strong>AutoPay notice</strong>
              <div>
                Removing this payment method will also disable AutoPay. AutoPay
                may be re-enabled at any time.
              </div>
            </CAlert>
          )}
          Are you sure you would like to remove payment method{" "}
          <strong style={{ whiteSpace: "nowrap" }}>
            {removeConfirm.display}
          </strong>
          ?
        </CModalBody>
        <CModalFooter style={{ display: "flex" }}>
          <CButton
            color="danger"
            style={{ flex: 1 }}
            onClick={async () => await handleRemoveConfirm()}
          >
            Remove
          </CButton>
          <CButton
            color="secondary"
            style={{ flex: 1 }}
            onClick={() => toggleRemoveConfirm(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>

      <CModal
        show={!!primaryConfirm}
        onClose={() => togglePrimaryConfirm(false)}
      >
        <CModalHeader>
          <strong>Confirm primary payment method preference</strong>
        </CModalHeader>
        <CModalBody>
          Are you sure you would like to set payment method{" "}
          <strong style={{ whiteSpace: "nowrap" }}>
            {primaryConfirm.display}
          </strong>{" "}
          as primary?
        </CModalBody>
        <CModalFooter style={{ display: "flex" }}>
          <CButton
            color="primary"
            style={{ flex: 1 }}
            onClick={async () => await handlePrimaryConfirm()}
          >
            Make Primary
          </CButton>
          <CButton
            color="secondary"
            style={{ flex: 1 }}
            onClick={() => togglePrimaryConfirm(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
