import React from "react";
import { CFade, CButton, CLink, CAlert } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import kingLogoExpandedBlue from "../assets/king-logo-expanded-blue.svg";

const AccountLinkingFailed: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <CFade>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ margin: 20 }}
      >
        <CAlert style={{ maxWidth: 500 }}>
          <h2>Error linking account</h2>
          <p>
            We&apos;re sorry, your invitation link may be invalid, expired, or
            previously consumed. Please ensure that you have the correct link
            and try again.
          </p>
          <p>
            If you&apos;re having trouble, please reach out to us to receive a
            new invitation link.
          </p>
          <p>
            Phone: <CLink href="tel://8007811765">(800) 781-1765</CLink>
            <br />
            Email:{" "}
            <CLink href="mailto:support@kingenergy.com">
              support@kingenergy.com
            </CLink>
          </p>
          <p>
            <CButton
              color="secondary"
              style={{ marginTop: 20 }}
              onClick={() => {
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
            >
              Log Out
            </CButton>
          </p>
          <div
            style={{ borderTop: "1px solid #303d7e", margin: "40px 0 20px 0" }}
          />
          <img src={kingLogoExpandedBlue} alt="" style={{ width: 220 }} />
        </CAlert>
      </div>
    </CFade>
  );
};

export default AccountLinkingFailed;
