import { combineReducers } from 'redux'
import { appReducer } from '../App.slice'
import { paymentHistoryReducer } from '../features/PaymentsHistory/Payment.slice'
import { statementSummaryReducer } from '../features/Dashboard/StatementSummary.slice'
import { statementReducer } from '../features/Statements/Statement.slice'
import { documentsReducer } from '../features/Documents/Documents.slice'
import { paymentMethodsReducer } from '../features/PaymentMethods/PaymentMethods.slice'
import { accountSummaryReducer } from '../AccountSummary.slice'
import { toasterReducer } from '../features/Toaster/Toaster.slice'
import { userReducer } from '../User.slice'

export const rootReducer = combineReducers({
  app: appReducer,
  accountSummary: accountSummaryReducer,
  payment: combineReducers({
    history: paymentHistoryReducer,
    methods: paymentMethodsReducer,
  }),
  statement: combineReducers({
    history: statementReducer,
    summary: statementSummaryReducer,
  }),
  toaster: toasterReducer,
  user: userReducer,
  documents: documentsReducer,
})
