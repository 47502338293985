import React from "react";
import { CFooter, CLink } from "@coreui/react";

export const TheFooter: React.FC = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <span className="mr-1">
          Copyright ©{new Date().getFullYear()} King Energy -{" "}
          <CLink href="https://www.kingenergy.com/privacy-policy">
            Privacy Policy
          </CLink>
        </span>
      </div>
      <div className="ml-auto">
        <span className="mr-1">
          <strong>Questions?</strong> Please give us a call at{" "}
          <CLink href="tel://8007811765">(800) 781-1765</CLink> or email us at{" "}
          <CLink href="mailto:support@kingenergy.com">
            support@kingenergy.com
          </CLink>
        </span>
      </div>
    </CFooter>
  );
};

export default TheFooter;
