export const Status: any = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
}

export interface PaymentActivityType {
  status: string
  amount: string
  paymentDate: string
  postingDate: string
  invoiceId: string
  paymentMethodId: string
  class?: string
}

export const addClassToActivitiesForStatus = (activities: PaymentActivityType[]) => {
  return activities.map(
    (activity: PaymentActivityType): PaymentActivityType => ({
      ...activity,
      class: activity.status === Status.SUCCEEDED ? 'badge bg-success' : 'badge bg-warning',
    })
  )
}
