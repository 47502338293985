import React, { useState, useEffect } from "react";
import {
  CFormGroup,
  CInput,
  CLabel,
  CButton,
  CAlert,
  CInputRadio,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
} from "@coreui/react";
import { getPaymentMethods } from "./../../features/PaymentMethods/PaymentMethods.slice";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AddBankAccountModal from "./../../features/PaymentMethods/AddBankAccountModal";
import AddCreditCardModal from "./../../features/PaymentMethods/AddCreditCardModal";
import { refreshAccountSummary } from "../../AccountSummary.slice";
import { pushToast } from "../../features/Toaster/Toaster.slice";
import { sendPaymentApi } from "../../apis/apis";
import { formatCurrency } from "../../utils/currency";
import { AutoPayDebitDetails } from "../../features/Dashboard/components/AutoPayDebitDetails";
import MakePaymentModal from "./MakePaymentModal";
import { formatDate } from "./../../utils/string";
import { trackEvent } from "./../../utils/analytics";
import SecureDataTransfer from "./../../components/SecureDataTransfer/SecureDataTransfer";
import { Status } from "../../features/PaymentsHistory/utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const MakePayment: React.FC = () => {
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [paymentConfirmModal, setPaymentConfirmModal] = useState<any>(false);
  const navigate = useNavigate();
  const accountSummary = useAppSelector((state) => state.accountSummary);
  const paymentMethods = useAppSelector((state) => state.payment.methods);
  const { loading: loadingPM } = paymentMethods;
  const { loading: loadingAM, data, activeTenantAccountIndex } = accountSummary;
  const tenantAccountData = data?.[activeTenantAccountIndex];
  const tenantAccountId = tenantAccountData?.id;
  const lastStatement = tenantAccountData?.invoice;
  const lastSuccessfulPayment = tenantAccountData?.lastSuccessfulPayment;
  const isAutopayEnabled = tenantAccountData?.autopay?.enabled;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPaymentMethods());
    trackEvent({ action: "View > Make Payment" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleBankAccountModal = (
    visible = !bankAccountModal,
    setFieldValue: any
  ) => {
    if (typeof setFieldValue === "function") {
      setFieldValue("paymentMethodId", paymentMethods?.data[0]?.id);
    }
    setBankAccountModal(visible);
  };

  const toggleCreditCardModal = (
    visible = !creditCardModal,
    setFieldValue: any
  ) => {
    if (typeof setFieldValue === "function") {
      setFieldValue("paymentMethodId", paymentMethods?.data[0]?.id);
    }
    setCreditCardModal(visible);
  };

  if (loadingAM || loadingPM) {
    return <div>Loading...</div>;
  }

  if (!lastStatement || lastStatement?.remainingBalance === 0) {
    return (
      <div className="flex-grow-1 justify-content-center">
        <CCard>
          <CCardHeader className="h4">Make Payment</CCardHeader>
          <CCardBody>
            No energy statements require payment at this time. Thank you.
          </CCardBody>
        </CCard>
      </div>
    );
  }

  const paymentMethodRadios = paymentMethods?.data?.map((pm, key: number) => (
    <div className="form-check" key={`pm-${key}`}>
      <Field
        as={CInputRadio}
        type="radio"
        name="paymentMethodId"
        value={pm.id}
        id={`pm-${key}`}
      />
      <CLabel htmlFor={`pm-${key}`}>{pm.display}</CLabel>
    </div>
  ));

  return (
    <div className="flex-grow-1 justify-content-center">
      <CCard>
        <Formik
          initialValues={{
            statementAmount: lastStatement.remainingBalance,
            paymentAmount: "BALANCE",
            paymentAmountOther: "",
            paymentMethodId:
              paymentMethods?.defaultPaymentMethod?.id ??
              paymentMethods?.data[0]?.id ??
              "",
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            statementAmount: Yup.string(),
            paymentAmount: Yup.string().required(
              "Please choose a payment amount"
            ),
            paymentAmountOther: Yup.string().when("paymentAmount", {
              is: "BALANCE",
              otherwise: (s) =>
                s
                  .required("Please enter a payment amount")
                  .test("num", "Must be a number", function (value: any) {
                    return !isNaN(Number(value));
                  })
                  .test(
                    "min",
                    "Must be greater than zero",
                    function (value: any) {
                      return Number(value) > 0;
                    }
                  )
                  .matches(
                    /^[0-9]+(.[0-9]{2})?$/,
                    "Please enter whole dollars and cents (e.g. 100.00)"
                  )
                  .test(
                    "max",
                    "Cannot exceed balance due",
                    function (value: any) {
                      const { statementAmount } = this.parent;
                      return Number(value) <= Number(statementAmount);
                    }
                  ),
            }),
            paymentMethodId: Yup.string().required(
              `Please ${
                paymentMethods?.data?.length === 0 ? "add" : "choose"
              } a payment account`
            ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const amount =
              values.paymentAmount === "BALANCE"
                ? lastStatement.remainingBalance
                : values.paymentAmountOther;
            const paymentMethodDisplay = paymentMethods?.data.filter(
              (pm: any) => pm.id === values.paymentMethodId
            )[0].display;

            try {
              await new Promise((resolve, reject) => {
                setPaymentConfirmModal(
                  <MakePaymentModal
                    amount={Number(amount)}
                    paymentMethodDisplay={paymentMethodDisplay}
                    resolve={resolve}
                    reject={reject}
                  />
                );
              });
            } catch (e: any) {
              setPaymentConfirmModal(false);
              setSubmitting(false);
              return;
            }

            try {
              const paymentTransaction = await sendPaymentApi(
                tenantAccountId,
                lastStatement.id,
                {
                  amount: Number(amount),
                  paymentDate: Date.now(),
                  paymentMethodId: values.paymentMethodId,
                }
              );

              if (paymentTransaction.status === Status.FAILED) {
                dispatch(
                  pushToast({
                    message: `Your payment of ${formatCurrency(
                      Number(amount)
                    )} from ${paymentMethodDisplay} failed.`,
                    type: "error",
                  })
                ).catch((e: string) => {
                  throw new Error(e);
                });
              } else {
                dispatch(
                  pushToast({
                    message: `Your payment of ${formatCurrency(
                      Number(amount)
                    )} from ${paymentMethodDisplay} was sent successfully. Thank you!`,
                    type: "success",
                  })
                ).catch((e: string) => {
                  throw new Error(e);
                });
                navigate("/thank-you", {
                  state: {
                    lastStatement,
                    paymentTransaction,
                  },
                });
              }

              dispatch(refreshAccountSummary()).catch((e: string) => {
                throw new Error(e);
              });
            } catch (e) {
              console.error(e);
              if (e instanceof Error) {
                dispatch(
                  pushToast({
                    message: `${e?.message ? `${e?.message}:` : "Error:"} ${
                      e.message
                    }`,
                    type: "error",
                  })
                ).catch((e: string) => {
                  throw new Error(e);
                });
              }
            } finally {
              setPaymentConfirmModal(false);
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            isValidating,
            isValid,
            submitCount,
            setFieldValue,
          }) => (
            <>
              <Form>
                <CCardHeader className="h2">Make Payment</CCardHeader>
                <CCardBody>
                  <CFormGroup
                    className={
                      errors.paymentAmountOther && touched.paymentAmountOther
                        ? "error"
                        : ""
                    }
                  >
                    <CLabel>Payment amount</CLabel>
                    <div className="form-check">
                      <Field
                        as={CInputRadio}
                        type="radio"
                        name="paymentAmount"
                        value="BALANCE"
                        id="payment-balance"
                      />
                      <CLabel htmlFor="payment-balance">
                        Balance due:{" "}
                        {formatCurrency(lastStatement.remainingBalance)}
                      </CLabel>
                    </div>
                    <div className="form-check">
                      <Field
                        as={CInputRadio}
                        type="radio"
                        name="paymentAmount"
                        value="OTHER"
                        id="payment-other"
                      />
                      <CLabel htmlFor="payment-other">Other amount</CLabel>
                    </div>
                    {values.paymentAmount === "OTHER" && (
                      <CFormGroup
                        className={
                          errors.paymentAmountOther &&
                          touched.paymentAmountOther
                            ? "error"
                            : ""
                        }
                      >
                        <div className="col-md-3" style={{ padding: 0 }}>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">$</div>
                            </div>
                            <Field
                              as={CInput}
                              name="paymentAmountOther"
                              id="payment-amount-other"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="error-text">
                          {errors.paymentAmountOther &&
                          touched.paymentAmountOther
                            ? (errors.paymentAmountOther as string)
                            : null}
                        </div>
                      </CFormGroup>
                    )}
                  </CFormGroup>
                  <CFormGroup className={errors.paymentMethodId ? "error" : ""}>
                    <CLabel>Payment account</CLabel>
                    {paymentMethodRadios}
                    {paymentMethods?.data?.length === 0 && (
                      <p>No bank accounts found</p>
                    )}
                    <div className="error-text">{errors.paymentMethodId}</div>
                    <CButton
                      onClick={() => toggleBankAccountModal(true, null)}
                      style={{ marginTop: 10 }}
                    >
                      Add Bank Account
                    </CButton>
                    <CButton
                      onClick={() => toggleCreditCardModal(true, null)}
                      style={{ marginTop: 10, marginLeft: 10 }}
                    >
                      Add Credit Card
                    </CButton>
                  </CFormGroup>
                  <div>
                    {lastSuccessfulPayment ? (
                      <p>
                        Last successful payment received on{" "}
                        <strong>
                          {formatDate(lastSuccessfulPayment.paymentDate)}
                        </strong>{" "}
                        for{" "}
                        <strong>
                          {formatCurrency(lastSuccessfulPayment.amount)}
                        </strong>
                        .
                      </p>
                    ) : (
                      <p>No prior payments have been received.</p>
                    )}
                    {isAutopayEnabled ? (
                      <p>
                        <strong>AutoPay is Enabled</strong>. AutoPay will debit{" "}
                        <AutoPayDebitDetails />.
                      </p>
                    ) : (
                      <p>
                        <strong>AutoPay is Disabled</strong>.
                      </p>
                    )}
                  </div>
                </CCardBody>
                <CCardFooter>
                  {!isValid && submitCount > 0 && (
                    <CAlert color="danger">
                      This form contains one or more errors. Please make
                      corrections and try again.
                    </CAlert>
                  )}
                  <CButton
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || (isValidating && !isValid)}
                  >
                    Make Payment
                  </CButton>
                  <SecureDataTransfer style={{ paddingLeft: 16 }} />
                </CCardFooter>
              </Form>
              {bankAccountModal && (
                <AddBankAccountModal
                  setModal={() => toggleBankAccountModal(false, setFieldValue)}
                  modal={bankAccountModal}
                />
              )}
              {creditCardModal && (
                <AddCreditCardModal
                  setModal={() => toggleCreditCardModal(false, setFieldValue)}
                  modal={creditCardModal}
                />
              )}
            </>
          )}
        </Formik>
      </CCard>

      {paymentConfirmModal}
    </div>
  );
};

export default MakePayment;
