import React from "react";
import { PaymentActivityType } from "../utils";
import { Column } from "../columns";

interface PropsInterface {
  columns?: Column[];
  activities?: PaymentActivityType[];
  handleCancelPayment?: any;
}

export const PaymentHistoryComponent: React.FC<PropsInterface> = (props) => {
  const { columns = [], activities = [], handleCancelPayment } = props;

  if (activities.length === 0) {
    return (
      <div className="flex-grow-1 justify-content-center">
        <div className="card">
          <header className="card-header">
            <h2>Payment History</h2>
          </header>
          <div className="card-body">
            No payment history found for this account.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-grow-1 justify-content-center">
      <div className="card">
        <header className="card-header" style={{ border: 0 }}>
          <h2>Payment History</h2>
        </header>

        {/* Mobile */}
        <table className="table table-striped table-hover d-lg-none">
          <tbody>
            {activities.map((activity, idx: number) => {
              return (
                <tr key={idx}>
                  <td>
                    {columns.map((column: any) => {
                      const render = column.render(
                        // @ts-ignore
                        activity[column.key],
                        activities,
                        idx,
                        handleCancelPayment
                      );
                      return (
                        render && (
                          <div key={column.key} style={{ padding: 10 }}>
                            <div>
                              <strong>{column.title}</strong>
                            </div>
                            <div>{render}</div>
                          </div>
                        )
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Desktop */}
        <table className="table table-striped table-hover d-md-down-none">
          <thead>
            <tr>
              {columns.map((column, key: number) => {
                return (
                  <th scope="col" key={`col-${key}`}>
                    {column.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {activities.map((activity, idx: number) => {
              return (
                <tr key={idx}>
                  {columns.map((column, columnIdx: number) => {
                    if (column.render) {
                      return (
                        <td key={columnIdx}>
                          {column.render(
                            // @ts-ignore
                            activity[column.key],
                            activities,
                            idx,
                            handleCancelPayment
                          )}
                        </td>
                      );
                    }
                    if (column.key in activity) {
                      return (
                        <td key={`col-${column.key}`}>
                          {/* @ts-ignore */}
                          {activity[column.key]}
                        </td>
                      );
                    }
                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
