import React from "react";
import { CAlert, CButton, CLink } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import kingLogoExpandedBlue from "../assets/king-logo-expanded-blue.svg";

const PasswordExpired: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ margin: 20, height: "100vh" }}
    >
      <CAlert style={{ maxWidth: 500 }}>
        <h2>Your password has expired</h2>
        <p>
          For your security, please change your password by clicking the
          &quot;Continue to Login&quot; button below, then click the
          &quot;Forgot Password?&quot; link as shown in the image below.
        </p>
        <p>
          <img
            src="forgot-password.png"
            alt=""
            style={{
              border: "1px solid #afafaf",
              borderRadius: 8,
              marginTop: 20,
            }}
          />
        </p>
        <p>
          <CButton
            color="primary"
            style={{ margin: "20px 0" }}
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            Continue to Login
          </CButton>
        </p>
        <p>
          Feel free to reach out with any questions you might have. we&apos;re
          here to help.
        </p>
        <p>
          Phone: <CLink href="tel://8007811765">(800) 781-1765</CLink>
          <br />
          Email:{" "}
          <CLink href="mailto:support@kingenergy.com">
            support@kingenergy.com
          </CLink>
          <div
            style={{ borderTop: "1px solid #303d7e", margin: "40px 0 20px 0" }}
          />
          <img src={kingLogoExpandedBlue} alt="" style={{ width: 220 }} />
        </p>
      </CAlert>
    </div>
  );
};

export default PasswordExpired;
