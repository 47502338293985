import React, { useState, useEffect } from "react";
import { CContainer, CFormGroup, CButton, CSelect } from "@coreui/react";
import { AutoPayComponent } from "../../features/Dashboard/components/AutoPayComponent";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { pushToast } from "../../features/Toaster/Toaster.slice";
import { setPaymentPreferenceApi } from "../../apis/apis";
import { Link } from "react-router-dom";
import { refreshAccountSummary } from "../../AccountSummary.slice";
import ChangePaymentConfirmModal from "../../features/Dashboard/components/ChangePaymentConfirmModal";
import { trackEvent } from "../../utils/analytics";
import { getPaymentMethods } from "../../features/PaymentMethods/PaymentMethods.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const AutoPayView: React.FC = () => {
  const [paymentSourceConfirm, setPaymentSourceConfirm] = useState<any>(false);
  const {
    loading: loadingAS,
    data,
    activeTenantAccountIndex,
  } = useAppSelector((state) => state.accountSummary);

  const accountSummaryData = data?.[activeTenantAccountIndex];
  const tenantAccountId = accountSummaryData?.id;
  const isAutopayEnabled = accountSummaryData?.autopay?.enabled;
  const autopayPaymentMethodId = accountSummaryData?.autopay?.paymentMethodId;

  const { loading: loadingPM, data: paymentMethods } = useAppSelector(
    (state) => state.payment.methods
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ action: "View > AutoPay" });
    dispatch(getPaymentMethods()).catch((e) => {
      throw new Error(e as string);
    });
  }, [dispatch]);

  if (loadingAS || loadingPM) {
    return <div>Loading...</div>;
  }

  const paymentMethodOptions = paymentMethods
    .filter((pm: any) => pm.id !== autopayPaymentMethodId)
    .map((pm: any, key: any) => (
      <option value={pm.id} key={`option-${key}`}>
        {pm.display}
      </option>
    ));

  return (
    <div className="flex-grow-1 justify-content-center">
      <AutoPayComponent />

      {isAutopayEnabled && paymentMethodOptions.length > 0 && (
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              <CContainer fluid>
                <CFormGroup>
                  <h5 className="card-title">Change AutoPay Payment Source</h5>
                  <Formik
                    initialValues={{
                      paymentMethodId: "",
                    }}
                    validationSchema={Yup.object().shape({
                      paymentMethodId: Yup.string().required(
                        "Please make a selection"
                      ),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        await new Promise((resolve, reject) => {
                          setPaymentSourceConfirm(
                            <ChangePaymentConfirmModal
                              paymentMethodDisplay={
                                paymentMethods.filter(
                                  (pm) => pm.id === values.paymentMethodId
                                )[0].display
                              }
                              resolve={resolve}
                              reject={reject}
                            />
                          );
                        });

                        trackEvent({
                          action: "AutoPay > Change Payment Source",
                        });

                        try {
                          await setPaymentPreferenceApi(
                            tenantAccountId,
                            "autopay",
                            true,
                            values.paymentMethodId
                          );
                          dispatch(refreshAccountSummary());
                          setPaymentSourceConfirm(false);
                          dispatch(
                            pushToast({
                              message:
                                "AutoPay payment source successfully changed",
                              type: "success",
                            })
                          ).catch((e: string) => {
                            throw new Error(e);
                          });
                        } catch (e: any) {
                          console.error(e);
                          dispatch(
                            pushToast({
                              message: `${
                                e.response?.data?.message
                                  ? `${e.response?.data?.message}:`
                                  : "Error:"
                              } ${e.message}`,
                              type: "error",
                            })
                          ).catch((e: string) => {
                            throw new Error(e);
                          });
                        }
                      } catch (e: any) {
                        setPaymentSourceConfirm(false);
                        return;
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      isSubmitting,
                      isValidating,
                      isValid,
                    }) => (
                      <Form>
                        <CFormGroup
                          className={
                            errors.paymentMethodId && touched.paymentMethodId
                              ? "error"
                              : ""
                          }
                        >
                          <Field as={CSelect} name="paymentMethodId">
                            <option value="">Select payment source</option>
                            {paymentMethodOptions}
                          </Field>
                          <div className="error-text">
                            {errors.paymentMethodId && touched.paymentMethodId
                              ? errors.paymentMethodId
                              : null}
                          </div>
                        </CFormGroup>
                        <p>
                          You may add additional payment sources in{" "}
                          <Link to="/payment-methods">Payment Methods</Link>.
                        </p>
                        <CButton
                          type="submit"
                          color="primary"
                          disabled={isSubmitting || (isValidating && !isValid)}
                        >
                          Change Payment Source
                        </CButton>
                      </Form>
                    )}
                  </Formik>
                </CFormGroup>
              </CContainer>
            </div>
          </div>
        </div>
      )}

      {paymentSourceConfirm}
    </div>
  );
};

export default AutoPayView;
