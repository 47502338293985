import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserProfileApi, getPreferenceApi } from './apis/apis'

const SLICE_NAME = "USER_SLICE";

export const getUserProfile = createAsyncThunk(
  `${SLICE_NAME}/getUserProfile`,
  async (_, { rejectWithValue }): Promise<any> => {
    try {
      return await getUserProfileApi();
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export const refreshUserProfile = createAsyncThunk(
  `${SLICE_NAME}/refreshUserProfile`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserProfileApi();
      return response as Profile;
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export const getMailingPreference = createAsyncThunk(
  `${SLICE_NAME}/getMailingPreference`,
  async (tenantAccountId: string, { rejectWithValue }) => {
    try {
      const response = await getPreferenceApi(tenantAccountId, "paperless");
      return response as MailingPreferenceData;
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export interface User {
  loading: boolean;
  error: boolean;
  data: UserData;
  profile: Profile;
  mailingPreference: MailingPreference;
}

export interface UserData {
  "https://account.kingenergy.com/tenantAccountGroupId": string;
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: Date;
  email: string;
  email_verified: boolean;
  sub: string;
}

export interface MailingPreference {
  loading: boolean;
  error: boolean;
  data: MailingPreferenceData;
}

export interface MailingPreferenceData {
  mailingAddress: MailingAddress;
  recipientName: string;
  paperless: boolean;
}

export interface MailingAddress {
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zip: string;
  type: string;
}

export interface Profile {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const initialState: User = {
  loading: true,
  error: false,
  data: {
    "https://account.kingenergy.com/tenantAccountGroupId": "",
    given_name: "",
    family_name: "",
    nickname: "",
    name: "",
    picture: "",
    updated_at: new Date(),
    email: "",
    email_verified: true,
    sub: "",
  },
  profile: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
  },
  mailingPreference: {
    loading: false,
    error: false,
    data: {
      mailingAddress: {
        address1: "",
        address2: null,
        city: "",
        state: "",
        zip: "",
        type: "",
      },
      recipientName: "",
      paperless: false,
    },
  },
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUser: (
      state: User,
      { payload }: { payload: UserData | { nickname: string; email: string } }
    ) => {
      state.data = { ...state.data, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state: User) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getUserProfile.fulfilled,
      (state: User, { payload }: { payload: Profile }) => {
        state.loading = false;
        state.error = false;
        state.profile = payload;
      }
    );
    builder.addCase(
      getUserProfile.rejected,
      (state: User, { payload }: { payload: unknown }) => {
        state.loading = false;
        state.error = payload != null;
      }
    );

    builder.addCase(
      refreshUserProfile.fulfilled,
      (state: User, { payload }: { payload: Profile }) => {
        state.loading = false;
        state.error = false;
        state.profile = payload;
      }
    );
    builder.addCase(
      refreshUserProfile.rejected,
      (state: User, { payload }: { payload: unknown }) => {
        state.loading = false;
        state.error = payload != null;
      }
    );

    builder.addCase(getMailingPreference.pending, (state: User) => {
      state.mailingPreference.loading = true;
      state.mailingPreference.error = false;
    });
    builder.addCase(
      getMailingPreference.fulfilled,
      (state: User, { payload }: { payload: MailingPreferenceData }) => {
        state.mailingPreference.loading = false;
        state.mailingPreference.error = false;
        state.mailingPreference.data = payload;
      }
    );
    builder.addCase(
      getMailingPreference.rejected,
      (state: User, { payload }: { payload: unknown }) => {
        state.mailingPreference.loading = false;
        state.mailingPreference.error = payload != null;
      }
    );
  },
});

export const { reducer: userReducer } = slice;
export const { setUser } = slice.actions;
