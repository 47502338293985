import React from "react";
import { refreshPaymentMethods } from "./PaymentMethods.slice";
import { createPaymentMethodApi } from "../../apis/apis";
import { pushToast } from "../Toaster/Toaster.slice";
import {
  CInput,
  CInputRadio,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CContainer,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInputCheckbox,
  CAlert,
} from "@coreui/react";
import { Formik, Form, Field } from "formik";
import SecureDataTransfer from "./../../components/SecureDataTransfer/SecureDataTransfer";
import * as Yup from "yup";
import imageBankAccount from "./images/bank-account.png";
import imageBankRouting from "./images/bank-routing.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface PropsInterface {
  modal: boolean;
  setModal: any;
}

const AddBankAccountModal: React.FC<PropsInterface> = (props) => {
  const { modal, setModal } = props;
  const dispatch = useAppDispatch();
  const paymentMethods = useAppSelector((state) => state.payment.methods.data);

  if (!modal) {
    return null;
  }

  const toggle = (visible = !modal) => {
    setModal(visible);
  };

  const isValidRoutingNumber = (t = "") => {
    let n = 0;
    for (let i = 0; i < t.length; i += 3) {
      n +=
        parseInt(t.charAt(i), 10) * 3 +
        parseInt(t.charAt(i + 1), 10) * 7 +
        parseInt(t.charAt(i + 2), 10);
    }

    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.
    return n !== 0 && n % 10 === 0;
  };

  const requireStr = "This field is required";

  return (
    <CModal show={modal} onClose={() => toggle(false)} closeOnBackdrop={false}>
      <Formik
        initialValues={{
          providerName: "",
          accountHolderName: "",
          accountNumber: "",
          accountNumberConfirm: "",
          routingNumber: "",
          paymentMethodType: "CHECKING",
          accountHolderType: "BUSINESS",
          primary: true,
        }}
        validationSchema={Yup.object().shape({
          providerName: Yup.string().trim().required(requireStr),
          accountHolderName: Yup.string().trim().required(requireStr),
          accountNumber: Yup.string()
            .matches(
              /^[0-9]+$/,
              "Must contain numbers only (no dashes or spaces)"
            )
            .matches(/^[0-9]{6,17}$/, "Must be 6 to 17 digits in length")
            .required(requireStr),
          accountNumberConfirm: Yup.string()
            .matches(
              /^[0-9]+$/,
              "Must contain numbers only (no dashes or spaces)"
            )
            .matches(/^[0-9]{6,17}$/, "Must be 6 to 17 digits in length")
            .test("max", "Account numbers must match", function (value: any) {
              const { accountNumber } = this.parent;
              return value === accountNumber;
            })
            .required(requireStr),
          routingNumber: Yup.string()
            .matches(
              /^[0-9]+$/,
              "Must contain numbers only (no dashes or spaces)"
            )
            .matches(/^[0-9]{9,}$/, "Must be 9 or more digits in length")
            .test(
              "isValidABA",
              "The number you entered is not a valid ABA routing number",
              (s: any) => isValidRoutingNumber(s)
            )
            .required(requireStr),
          paymentMethodType: Yup.string().required(requireStr),
          accountHolderType: Yup.string().required(requireStr),
        })}
        onSubmit={async (values: any, { setSubmitting }) => {
          try {
            values.accountNumberConfirm = undefined;
            await createPaymentMethodApi(values);
            setSubmitting(false);
            toggle(false);
            dispatch(
              pushToast({
                message: "Payment method successfully added",
                type: "success",
              })
            ).catch((e: string) => {
              throw new Error(e);
            });
          } catch (e: any) {
            console.error(e);
            dispatch(
              pushToast({
                message: `${
                  e.response?.data?.message
                    ? `${e.response?.data?.message}:`
                    : "Error:"
                } ${e.message}`,
                type: "error",
              })
            ).catch((e: string) => {
              throw new Error(e);
            });
          }
          dispatch(refreshPaymentMethods()).catch((e: string) => {
            throw new Error(e);
          });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          isValidating,
          isValid,
          submitCount,
        }) => (
          <Form>
            <CModalHeader>
              <strong>Add Bank Account</strong>
            </CModalHeader>
            <CModalBody>
              <CContainer fluid>
                <CRow>
                  <CCol sm="12">
                    <CFormGroup
                      className={
                        errors?.providerName && touched?.providerName
                          ? "error"
                          : ""
                      }
                    >
                      <CLabel htmlFor="nf-account">
                        Name of Bank
                        <br />
                        <span style={{ fontSize: 10 }}>
                          (e.g. &quot;Bank of America&quot;)
                        </span>
                      </CLabel>
                      <Field
                        as={CInput}
                        name="providerName"
                        id="nf-providerName"
                      />
                      <div className="error-text">
                        {errors?.providerName && touched?.providerName
                          ? (errors.providerName as string)
                          : null}
                      </div>
                    </CFormGroup>

                    <CFormGroup
                      className={
                        errors?.accountHolderName && touched?.accountHolderName
                          ? "error"
                          : ""
                      }
                    >
                      <CLabel htmlFor="nf-account">
                        Account Holder Name
                        <br />
                        <span style={{ fontSize: 10 }}>
                          (e.g. &quot;Jones Services LLC&quot; or &quot;Maria
                          Jones&quot;)
                        </span>
                      </CLabel>
                      <Field
                        as={CInput}
                        name="accountHolderName"
                        id="nf-accountHolderName"
                      />
                      <div className="error-text">
                        {errors?.accountHolderName && touched?.accountHolderName
                          ? (errors.accountHolderName as string)
                          : null}
                      </div>
                    </CFormGroup>

                    <div className="focus-group">
                      <CFormGroup
                        className={
                          errors?.routingNumber && touched?.routingNumber
                            ? "error"
                            : ""
                        }
                      >
                        <CLabel htmlFor="nf-account">Routing Number</CLabel>
                        <Field
                          as={CInput}
                          name="routingNumber"
                          id="nf-routingNumber"
                        />
                        <div className="error-text">
                          {errors?.routingNumber && touched?.routingNumber
                            ? (errors.routingNumber as string)
                            : null}
                        </div>
                      </CFormGroup>
                      <img
                        src={imageBankRouting}
                        alt=""
                        className="focus-element"
                      />
                    </div>

                    <div className="focus-group">
                      <CFormGroup
                        className={
                          errors?.accountNumber && touched?.accountNumber
                            ? "error"
                            : ""
                        }
                      >
                        <CLabel htmlFor="nf-account">
                          Bank Account Number
                        </CLabel>
                        <Field
                          as={CInput}
                          name="accountNumber"
                          id="nf-accountNumber"
                        />
                        <div className="error-text">
                          {errors?.accountNumber && touched?.accountNumber
                            ? (errors.accountNumber as string)
                            : null}
                        </div>
                      </CFormGroup>
                      <CFormGroup
                        className={
                          errors?.accountNumberConfirm &&
                          touched?.accountNumberConfirm
                            ? "error"
                            : ""
                        }
                      >
                        <CLabel htmlFor="nf-account">
                          Bank Account Number (confirm)
                        </CLabel>
                        <Field
                          as={CInput}
                          name="accountNumberConfirm"
                          id="nf-accountNumberConfirm"
                        />
                        <div className="error-text">
                          {errors?.accountNumberConfirm &&
                          touched?.accountNumberConfirm
                            ? (errors.accountNumberConfirm as string)
                            : null}
                        </div>
                      </CFormGroup>
                      <img
                        src={imageBankAccount}
                        alt=""
                        className="focus-element"
                      />
                    </div>

                    <CFormGroup
                      className={
                        errors?.paymentMethodType && touched?.paymentMethodType
                          ? "error"
                          : ""
                      }
                    >
                      <CLabel htmlFor="nf-account">Account Type</CLabel>
                      <div className="form-check">
                        <Field
                          as={CInputRadio}
                          type="radio"
                          name="paymentMethodType"
                          value="CHECKING"
                          id="type-checking"
                        />
                        <CLabel htmlFor="type-checking">Checking</CLabel>
                      </div>
                      <div className="form-check">
                        <Field
                          as={CInputRadio}
                          type="radio"
                          name="paymentMethodType"
                          value="SAVINGS"
                          id="type-savings"
                        />
                        <CLabel htmlFor="type-savings">Savings</CLabel>
                      </div>
                      <div className="error-text">
                        {errors?.paymentMethodType && touched?.paymentMethodType
                          ? (errors.paymentMethodType as string)
                          : null}
                      </div>
                    </CFormGroup>

                    <CFormGroup
                      className={
                        errors?.accountHolderType && touched?.accountHolderType
                          ? "error"
                          : ""
                      }
                    >
                      <CLabel htmlFor="nf-account">Account Holder Type</CLabel>
                      <div className="form-check">
                        <Field
                          as={CInputRadio}
                          type="radio"
                          name="accountHolderType"
                          value="BUSINESS"
                          id="type-business"
                        />
                        <CLabel htmlFor="type-business">Business</CLabel>
                      </div>
                      <div className="form-check">
                        <Field
                          as={CInputRadio}
                          type="radio"
                          name="accountHolderType"
                          value="PERSONAL"
                          id="type-personal"
                        />
                        <CLabel htmlFor="type-personal">Personal</CLabel>
                      </div>
                      <div className="error-text">
                        {errors?.accountHolderType && touched?.accountHolderType
                          ? (errors.accountHolderType as string)
                          : null}
                      </div>
                    </CFormGroup>

                    {!!paymentMethods.length && (
                      <CFormGroup>
                        <CLabel>Primary payment method</CLabel>
                        <div className="form-check">
                          <Field
                            as={CInputCheckbox}
                            type="checkbox"
                            name="primary"
                            id="primary-method"
                          />
                          <CLabel htmlFor="primary-method">
                            Use this account as my primary payment method
                          </CLabel>
                        </div>
                      </CFormGroup>
                    )}
                  </CCol>
                </CRow>
              </CContainer>
              {Object.keys(errors).length > 0 && submitCount > 0 && (
                <CAlert color="danger">
                  This form contains one or more errors. Please make corrections
                  and try again.
                </CAlert>
              )}
            </CModalBody>
            <CModalFooter>
              <SecureDataTransfer style={{ flex: 1 }} />
              <CButton
                type="submit"
                color="primary"
                disabled={isSubmitting || (isValidating && !isValid)}
              >
                Add Bank Account
              </CButton>{" "}
              <CButton color="secondary" onClick={() => toggle(false)}>
                Cancel
              </CButton>
            </CModalFooter>
          </Form>
        )}
      </Formik>
    </CModal>
  );
};

export default AddBankAccountModal;
