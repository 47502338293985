import VisaLogo from './images/visa.svg'
import MastercardLogo from './images/mastercard.svg'
import AmexLogo from './images/amex.svg'
import GenericLogo from './images/generic.svg'

export const PAYMENT_TYPE = {
  AMERICAN_EXPRESS: {
    KEY: 'AMERICAN_EXPRESS',
    LOGO: AmexLogo,
  },
  VISA: {
    KEY: 'VISA',
    LOGO: VisaLogo,
  },
  MASTERCARD: {
    KEY: 'MASTERCARD',
    LOGO: MastercardLogo,
  },
  CARD: {
    KEY: 'CARD',
    LOGO: GenericLogo,
  },
  CHECKING: { KEY: 'CHECKING', LOGO: 'cil-bank' },
  SAVINGS: { KEY: 'SAVINGS', LOGO: 'cil-bank' },
}

export enum PAYMENT_METHOD_STATUS {
  'Active' = 'ACTIVE' as any,
  'Pending verification' = 'PENDING_VERIFICATION' as any,
  'Expired' = 'EXPIRED' as any,
}
