import React from "react";
import { CFade, CCol, CRow, CCard } from "@coreui/react";
import UserProfileForm from "./components/ContactDetailsForm";
import MailingPreferenceForm from "./components/MailingPreferenceForm";

export const UserProfile: React.FC = () => {
  return (
    <CFade>
      <CRow className="justify-content-evenly">
        <CCol sm={6}>
          <CCard>
            <UserProfileForm />
          </CCard>
        </CCol>
        <CCol sm={6}>
          <CCard>
            <MailingPreferenceForm />
          </CCard>
        </CCol>
      </CRow>
    </CFade>
  );
};

export default UserProfile;
