import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const SLICE_NAME = 'TOASTER_SLICE'

export const pushToast = createAsyncThunk(`${SLICE_NAME}/pushToast`, (payload: any) => payload)

export interface Toaster {
  loading: boolean;
  error: boolean;
  data: Datum[] | [];
}

export interface Datum {
  message: string;
  type: string;
}

const initialState: Toaster = {
  loading: false,
  error: false,
  data: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pushToast.pending, (state: Toaster) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(pushToast.fulfilled, (state: Toaster, { payload }: any) => {
      state.loading = false;
      state.error = false;
      state.data = [...state.data, payload];
    });
    builder.addCase(pushToast.rejected, (state: Toaster, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { reducer: toasterReducer } = slice
