import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getDocuments } from "./Documents.slice";
import { CFade } from "@coreui/react";
import iconDocument from "./images/document.svg";
import { trackEvent } from "./../../utils/analytics";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const Documents: React.FC = () => {
  const { loading: loadingDocs, data } = useAppSelector(
    (state) => state.documents
  );
  const { loading: loadingSummary, activeTenantAccountId: tenantAccountId } =
    useAppSelector((state) => state.accountSummary);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tenantAccountId) {
      dispatch(getDocuments(tenantAccountId)).catch((e: string) => {
        throw new Error(e);
      });
      trackEvent({ action: "View > Documents" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantAccountId]);

  if (loadingDocs || loadingSummary) {
    return (
      <CFade>
        <div className="flex-grow-1 justify-content-center">
          <div className="card">
            <header className="card-header">
              <h2>Documents</h2>
            </header>
            <div className="card-body">
              <div>Loading...</div>
            </div>
          </div>
        </div>
      </CFade>
    );
  }

  if (!data?.length) {
    return (
      <CFade>
        <div className="flex-grow-1 justify-content-center">
          <div className="card">
            <header className="card-header">
              <h2>Documents</h2>
            </header>
            <div className="card-body">
              <div>
                There are no documents available for your account at this time.
              </div>
            </div>
          </div>
        </div>
      </CFade>
    );
  }

  return (
    <CFade>
      <div className="flex-grow-1 justify-content-center">
        <div className="card">
          <header className="card-header">
            <h2>Documents</h2>
          </header>
          <div className="card-body">
            <CFade>
              <p>
                This section holds service-related documents specific to your
                account.
              </p>
              <div className="docs">
                {data.map((doc: any, index: any) => (
                  <Link
                    to="/document"
                    state={{
                      link: doc.link,
                      tenantAccountId,
                    }}
                    className="doc"
                    key={index}
                  >
                    <img
                      src={iconDocument}
                      alt="Document"
                      className="mr-3 w-20"
                    />
                    <div>
                      <strong>{doc.documentLabel}</strong>
                      <br />
                      <span className="text-sm text-muted">
                        {doc.categoryLabel}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </CFade>
          </div>
        </div>
      </div>
    </CFade>
  );
};

export default Documents;
