import React, { useState, useEffect } from "react";
import { pushToast } from "../../Toaster/Toaster.slice";
import { refreshAccountSummary } from "../../../AccountSummary.slice";
import { getMailingPreference } from "../../../User.slice";
import { setPaymentPreferenceApi } from "../../../apis/apis";
import {
  CSwitch,
  CLink,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export const PaperlessComponent: React.FC = () => {
  const [paperlessConfirm, setPaperlessConfirm] = useState(false);
  const { loading, data, activeTenantAccountIndex, activeTenantAccountId } =
    useAppSelector((state) => state.accountSummary);
  const mailingPreference = useAppSelector(
    (state) => state.user.mailingPreference
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeTenantAccountId) {
      dispatch(getMailingPreference(activeTenantAccountId))
        .then(() => "Success")
        .catch((e: string) => {
          throw new Error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTenantAccountId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const accountSummaryData = data?.[activeTenantAccountIndex];
  const tenantAccountId = accountSummaryData?.id
  const paperless = accountSummaryData?.paperless

  if (paperless === undefined) {
    return null;
  }

  const setPreference = (preferenceType: string, value: boolean) => {
    (async () => {
      try {
        await setPaymentPreferenceApi(tenantAccountId, preferenceType, value);
        dispatch(refreshAccountSummary()).catch((e: string) => {
          throw new Error(e);
        });
        dispatch(
          pushToast({
            message: `${
              preferenceType === "autopay"
                ? "AutoPay"
                : "Paperless E-Statements"
            } ${value ? "enabled" : "disabled"}`,
            type: "success",
          })
        ).catch((e: string) => {
          throw new Error(e);
        });
      } catch (e: any) {
        console.error(e);
        dispatch(
          pushToast({
            message: `${
              e.response?.data?.message
                ? `${e.response?.data?.message}:`
                : "Error:"
            } ${e.message}`,
            type: "error",
          })
        ).catch((e: string) => {
          throw new Error(e);
        });
      }
    })().catch((e: string) => {
      throw new Error(e);
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Paperless E-Statements</h2>
          <div>
            {paperless && (
              <div>
                <p>
                  <strong>
                    You are currently enrolled in paperless e-statements.
                  </strong>
                </p>
                <div>
                  To change your email address, please give us a call at{" "}
                  <CLink href="tel://8007811765">(800) 781-1765</CLink> or email
                  us at{" "}
                  <CLink href="mailto:support@kingenergy.com">
                    support@kingenergy.com
                  </CLink>
                </div>
              </div>
            )}

            {!paperless && (
              <div>
                <p>
                  <strong>
                    You are not currently enrolled in paperless e-statements.
                  </strong>
                </p>
                {mailingPreference?.data?.mailingAddress &&
                  !mailingPreference?.loading && (
                    <div>
                      <div>Statements will be mailed to:</div>
                      <div className="py-2" style={{ fontWeight: 600 }}>
                        {mailingPreference?.data?.recipientName}
                        <br />
                        {mailingPreference?.data?.mailingAddress?.address1}{" "}
                        {mailingPreference?.data?.mailingAddress?.address2}
                        <br />
                        {mailingPreference?.data?.mailingAddress?.city},{" "}
                        {mailingPreference?.data?.mailingAddress?.state}{" "}
                        {mailingPreference?.data?.mailingAddress?.zip}
                        <br />
                      </div>
                      <Link to="/account-profile">
                        <CButton className="mt-2">
                          Manage mailing address
                        </CButton>
                      </Link>
                    </div>
                  )}
              </div>
            )}

            <div className="mt-4 mb-2">
              Tap the toggle button below to turn paperless e-statements{" "}
              {paperless ? "off" : "on"}.
            </div>
            <ul className="list-group">
              <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                <span className="font-weight-bold">Paperless E-Statements</span>
                <CSwitch
                  key="auto_pay"
                  color="success"
                  labelOn="On"
                  labelOff="Off"
                  value="success"
                  shape="pill"
                  checked={paperless}
                  onChange={() => setPaperlessConfirm(true)}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <CModal
        show={paperlessConfirm}
        onClose={() => setPaperlessConfirm(false)}
      >
        <CModalHeader>
          <strong>Confirm Paperless E-Statement Preference</strong>
        </CModalHeader>
        <CModalBody>
          {paperless && (
            <p>
              By turning E-Statements off, you acknowledge that subsequent
              energy statements will be delivered by mail to the billing address
              on file.
            </p>
          )}

          {!paperless && (
            <>
              <p>
                Your E-Statements may be sent to you electronically either by
                <br />
                (i) sending the E-Statement to your email address, or
                <br />
                (ii) posting the E-Statement on a website and sending a notice
                to your email address that the E-Statement is available, with
                instructions on how to view it.
              </p>
              <p>
                E-Statements will be deemed delivered when we send the
                E-Statement or the notice of availability to your email address.
              </p>
              <p>
                You agree we may use any email address you provide to us until
                you change it, or tell us it is no longer active.
              </p>
              <p>
                We may also deliver any other information we would normally
                include with your monthly statement the same way, including our
                annual privacy policy notice.
              </p>
              <p>
                By turning E-Statements on, I acknowledge that paperless billing
                will be enabled, statements we will delivered to the email
                address on file, and I agree to the conditions listed above.
              </p>
            </>
          )}
        </CModalBody>
        <CModalFooter style={{ display: "flex" }}>
          <CButton
            color={paperless ? "danger" : "primary"}
            style={{ flex: 2 }}
            onClick={() => {
              setPaperlessConfirm(false);
              setPreference("paperless", !paperless);
            }}
          >
            Turn {paperless ? "off" : "on"} paperless e-statements
          </CButton>
          <CButton
            color="secondary"
            style={{ flex: 1 }}
            onClick={() => setPaperlessConfirm(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
