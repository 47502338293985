import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";

interface PropsInterface {
  paymentMethodDisplay: string;
  resolve: any;
  reject: any;
}

const ChangePaymentConfirmModal: React.FC<PropsInterface> = (props) => {
  const { paymentMethodDisplay, resolve, reject } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <CModal show onClose={reject}>
      <CModalHeader>
        <strong>Change AutoPay Payment Source</strong>
      </CModalHeader>
      <CModalBody>
        <p>
          Are you sure you want to change the AutoPay payment source to{" "}
          <strong style={{ whiteSpace: "nowrap" }}>
            {paymentMethodDisplay}
          </strong>
          ?
        </p>
      </CModalBody>
      <CModalFooter style={{ display: "flex" }}>
        <CButton
          color="primary"
          style={{ flex: 2 }}
          disabled={isSubmitting}
          onClick={() => {
            setSubmitting(true);
            resolve();
          }}
        >
          Change Payment Source
        </CButton>
        <CButton color="secondary" style={{ flex: 1 }} onClick={reject}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ChangePaymentConfirmModal;
