import { formatCurrency } from "../../utils/currency";
import { CButton } from "@coreui/react";
import { formatUTCDate } from "./../../utils/string";
import Badge from "./../../components/Badge";

export interface Column {
  key: string;
  title: string;
  render: Function;
}

export const COLUMNS = [
  {
    key: "paymentNumber",
    title: "Transaction Number",
    render: (paymentNumber: string) => <span>{paymentNumber}</span>,
  },
  {
    key: "paymentDate",
    title: "Date Paid",
    render: (date: string) => <span>{formatUTCDate(date)}</span>,
  },
  {
    key: "postingDate",
    title: "Date Posted",
    render: (date: string) => <span>{date && formatUTCDate(date)}</span>,
  },
  {
    key: "amount",
    title: "Payment Amount",
    render: (amount: string) => <span>{formatCurrency(amount)}</span>,
  },
  {
    key: "paymentTypeDisplay",
    title: "Payment Type",
    render: (paymentTypeDisplay: string) => <span>{paymentTypeDisplay}</span>,
  },
  {
    key: "display",
    title: "Payment Method",
    render: (display: string) => <span>{display}</span>,
  },
  {
    key: "status",
    title: "Status",
    render: (status: string) => {
      return <Badge status={status} />;
    },
  },
  {
    key: "canCancel",
    title: "",
    render: (
      canCancel: boolean,
      activities: any,
      activityIdx: number,
      handleCancelPayment: any
    ) =>
      canCancel && (
        <CButton
          style={{ whiteSpace: "nowrap" }}
          onClick={() => handleCancelPayment(activities[activityIdx])}
        >
          Cancel
        </CButton>
      ),
  },
];
