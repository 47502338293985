import React, { useEffect } from "react";
import { getStatements } from "./Statement.slice";
import { StatementComponent } from "./components/StatementComponent";
import { COLUMNS } from "./columns";
import { CFade } from "@coreui/react";
import { trackEvent } from "./../../utils/analytics";
import { Link } from "react-router-dom";
import { CButton } from "@coreui/react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const Statements: React.FC = () => {
  const { loading: loadingSMT, data } = useAppSelector(
    (state) => state.statement.history
  );
  const { activeTenantAccountId, loading: loadingAS } = useAppSelector(
    (state) => state.accountSummary
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStatements()).catch((e) => {
      throw new Error(e as string);
    });
    trackEvent({ action: "View > Statements" });
  }, [activeTenantAccountId, dispatch]);

  const getColumns = () => {
    const dataHasExternalLink = data?.some(
      (statement) => statement.invoiceExternalLink
    );

    if (dataHasExternalLink) {
      return [
        ...COLUMNS,
        {
          key: "invoiceExternalLink",
          title: "Original Utility Statement",
          render: (invoiceExternalLink: any) => {
            if (!invoiceExternalLink) return;

            return (
              <Link to="/statement" state={{ link: invoiceExternalLink }}>
                <CButton style={{ whiteSpace: "nowrap" }}>View</CButton>
              </Link>
            );
          },
        },
      ];
    }

    return COLUMNS;
  };

  if (loadingSMT || loadingAS) {
    return (
      <CFade>
        <div className="flex-grow-1 justify-content-center">
          <div className="card">
            <header className="card-header">
              <h2 className="card-title">Statements</h2>
            </header>
            <div className="card-body">Loading...</div>
          </div>
        </div>
      </CFade>
    );
  }

  if (!data?.length) {
    return (
      <CFade>
        <div className="flex-grow-1 justify-content-center">
          <div className="card">
            <header className="card-header">
              <h2 className="card-title">Statements</h2>
            </header>
            <div className="card-body">
              There are no statements available for your account at this time.
            </div>
          </div>
        </div>
      </CFade>
    );
  }

  return (
    <CFade>
      <StatementComponent columns={getColumns()} dataSource={data} />
    </CFade>
  );
};

export default Statements;
