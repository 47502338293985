import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { store } from "./store/store";
import { setupSentry } from "./utils/sentry";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MakePayment from "./views/make-payment/MakePayment";
import ThankYou from "./views/make-payment/ThankYou";
import PaymentHistory from "./features/PaymentsHistory/PaymentHistory";
import AutoPay from "./views/autopay/AutoPay";
import Statements from "./features/Statements/Statements";
import Documents from "./features/Documents/Documents";
import PaymentMethods from "./features/PaymentMethods/PaymentMethods";
import Paperless from "./views/paperless/Paperless";
import Profile from "./features/Profile/Profile";
import Document from "./features/Documents/Document";
import Statement from "./features/Statements/Statement";
import Dashboard from "./features/Dashboard/Dashboard";
import ErrorBoundary from "./containers/ErrorBoundary";
import RegisterAccount from "./views/RegisterAccount";

setupSentry();

// @ts-ignore
React.icons = icons;

const {
  VITE_AUTH0_DOMAIN: AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE,
  VITE_AUTH0_SCOPE: AUTH0_SCOPE,
} = import.meta.env;

const isImpersonating = window.location !== window.parent.location; // App is loaded in an iframe

const router = createBrowserRouter([
  {
    path: "/register",
    element: <RegisterAccount />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/document",
        element: <Document />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/statement",
        element: <Statement />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/logout",
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/make-payment",
        element: <MakePayment />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/thank-you",
        element: <ThankYou />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/payment-history",
        element: <PaymentHistory />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/auto-pay",
        element: <AutoPay />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/statements",
        element: <Statements />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/documents",
        element: <Documents />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/payment-methods",
        element: <PaymentMethods />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/paperless",
        element: <Paperless />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/account-profile",
        element: <Profile />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);

const StrictModeOrFragment = import.meta.env.PROD
  ? React.StrictMode
  : React.Fragment;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictModeOrFragment>
    <Provider store={store}>
      {isImpersonating ? (
        <RouterProvider router={router} />
      ) : (
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          authorizationParams={{
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPE,
            redirect_uri: window.location.origin,
          }}
          cacheLocation="localstorage"
        >
          <RouterProvider router={router} />
        </Auth0Provider>
      )}
    </Provider>
  </StrictModeOrFragment>
);
