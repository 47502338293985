import { createSlice } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

const APP_SLICE_NAME = "APP_SLICE";

export interface App {
  sidebarShow: string;
  asideShow: boolean;
  darkMode: boolean;
}

const initialState: App = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};

const appSlice = createSlice({
  name: APP_SLICE_NAME,
  initialState,
  reducers: {
    setSidebarShow: (state: App, { payload }: { payload: string }) => {
      state.sidebarShow = payload;
    },
    setAsideShow: (state: App, { payload }: { payload: boolean }) => {
      state.asideShow = payload;
    },
    setDarkMode: (state: App, { payload }: { payload: boolean }) => {
      state.darkMode = payload;
    },
  },
});

export const {
  reducer: appReducer,
  actions: { setSidebarShow, setAsideShow, setDarkMode },
} = appSlice

export const useAppStateSelector: TypedUseSelectorHook<App> = useSelector;
