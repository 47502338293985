import React from "react";
import { Link } from "react-router-dom";
import { CButton } from "@coreui/react";
import { formatCurrency } from "../../../utils/currency";
import { formatUTCDate } from "../../../utils/string";
import Badge from "../../../components/Badge";

interface PropsInterface {
  activities?: any[];
}

export const PaymentHistoryComponent: React.FC<PropsInterface> = (props) => {
  const { activities } = props;

  if (!activities?.length) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Payment History</h2>
          <div className="card-text">
            No payment history found for this account.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2 className="card-title">Payment History</h2>
        <div className="card-text">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Activity</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity: any, key: any) => (
                <tr key={`row-${key}`}>
                  <th scope="row">{formatUTCDate(activity.paymentDate)}</th>
                  <td>
                    {formatCurrency(activity.amount)} payment{" "}
                    <Badge status={activity.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>{" "}
        <p />
        <Link to="/payment-history">
          <CButton>See Full History</CButton>
        </Link>
      </div>
    </div>
  );
};

export default PaymentHistoryComponent;
