import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CButton } from "@coreui/react";
import { formatCurrency } from "../../../utils/currency";
import { AutoPayDebitDetails } from "./AutoPayDebitDetails";
import { formatUTCDate } from "../../../utils/string";
import Badge from "../../../components/Badge";

interface PropsInterface {
  summary: any;
  isAutopayEnabled: boolean;
  payment?: any;
}

export const StatementSummaryComponent: React.FC<PropsInterface> = (props) => {
  const { summary, isAutopayEnabled } = props;
  const navigate = useNavigate();
  const TITLE = "Statement Summary";

  if (!summary) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">{TITLE}</h2>
          <div className="card-text">
            No statements available for this account at this time.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2 className="card-title">{TITLE}</h2>
        <div className="card-text">
          <ul className="list-group">
            <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
              <span className="font-weight-bold">Due Date</span>{" "}
              <span className="text-right">
                {formatUTCDate(summary.paymentDueDate)}
              </span>
            </li>
            <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
              <span className="font-weight-bold">Original Balance</span>{" "}
              <span className="text-right">
                {formatCurrency(summary.invoiceAmount)}
              </span>
            </li>
            <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
              <span className="font-weight-bold">Amount Due</span>{" "}
              <span className="text-right">
                {formatCurrency(summary.remainingBalance)}
              </span>
            </li>
            <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
              <span className="font-weight-bold">Status</span>{" "}
              <span className="text-right">
                <Badge status={summary.invoiceStatus} />
              </span>
            </li>
            <li
              className="list-group-item d-flex list-group-item-action justify-content-between align-items-center"
              style={{ gridGap: 10 }}
            >
              <span className="font-weight-bold">Statement Period</span>{" "}
              <span className="text-right">
                {formatUTCDate(summary.billingStartDate)} -{" "}
                {formatUTCDate(summary.billingEndDate)}
              </span>
            </li>
            <li
              className="list-group-item d-flex list-group-item-action justify-content-between align-items-center"
              style={{ gridGap: 10 }}
            >
              <span className="font-weight-bold">AutoPay</span>
              <span className="text-right">
                {isAutopayEnabled ? (
                  <span>
                    Debit <AutoPayDebitDetails />.
                  </span>
                ) : (
                  <span>Off</span>
                )}
              </span>
            </li>
          </ul>
        </div>
        <p />

        <div className="d-flex justify-content-between">
          <Link to="/statement" state={{ link: summary.invoiceLink }}>
            <CButton>See Current Statement</CButton>
          </Link>
          {summary.remainingBalance > 0 && (
            <CButton
              color="primary"
              onClick={() => {
                navigate("/make-payment");
              }}
            >
              Make Payment
            </CButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatementSummaryComponent;
