export const logoNegative = [
  '231 35',
  `

  <title>coreui react pro logo-neg</title>
  <g transform="translate(0.000000,35.000000) scale(0.100000,-0.100000)"
fill="#F03E3E" stroke="none">
<path d="M0 175 l0 -175 195 0 195 0 0 175 0 175 -38 0 c-26 0 -45 -7 -57 -20
-19 -21 -45 -27 -45 -10 0 5 6 7 13 4 8 -3 20 2 27 11 8 9 8 15 2 15 -6 0 -13
-4 -16 -9 -7 -10 -25 -9 -43 2 -16 10 -103 6 -109 -5 -3 -5 -12 -4 -20 3 -9 7
-14 8 -14 1 0 -18 20 -24 35 -12 11 9 15 9 15 1 0 -22 -31 -24 -54 -2 -13 12
-35 21 -54 21 l-32 0 0 -175z m350 -47 l0 -93 -159 0 -158 0 1 85 1 85 155 5
c85 3 156 7 158 8 1 1 2 -40 2 -90z"/>
<path d="M50 180 c0 -13 7 -20 20 -20 16 0 20 -7 20 -35 0 -28 -4 -35 -20 -35
-13 0 -20 -7 -20 -20 0 -18 7 -20 60 -20 53 0 60 2 60 20 0 14 -7 20 -22 20
-20 1 -19 2 7 14 20 9 34 10 43 3 7 -5 10 -13 6 -16 -4 -3 -9 0 -11 7 -8 22
-21 3 -15 -23 4 -24 7 -25 78 -25 67 0 74 2 74 20 0 15 -7 20 -28 20 -17 0
-38 10 -51 24 -23 24 -23 24 -3 35 12 6 34 11 50 11 21 0 31 6 35 20 5 19 0
20 -139 20 -137 0 -144 -1 -144 -20z"/>
<path d="M562 278 c-15 -15 -16 -45 -2 -53 6 -3 10 -30 10 -60 0 -30 -4 -57
-10 -60 -5 -3 -10 -14 -9 -23 0 -14 2 -13 10 3 7 17 8 15 6 -10 l-2 -30 204
-2 c112 -1 201 0 198 3 -3 3 -94 6 -204 7 -109 1 -177 3 -150 5 38 2 47 7 47
22 0 10 -6 20 -12 23 -7 2 -5 3 5 1 10 -1 16 3 14 9 -2 7 -13 11 -23 9 -14 -2
-16 -1 -5 6 11 8 11 10 0 15 -11 4 -11 6 1 6 8 0 23 -11 33 -24 10 -14 13 -25
7 -25 -5 0 -10 -9 -10 -20 0 -17 7 -20 45 -20 38 0 45 3 45 20 0 11 -6 20 -12
20 -7 0 -24 16 -37 35 -23 34 -23 36 -6 61 9 15 26 29 36 31 10 3 19 14 19 24
0 16 -8 19 -45 19 -38 0 -45 -3 -45 -20 0 -11 5 -20 12 -20 6 0 3 -9 -7 -20
-18 -20 -51 -28 -40 -10 4 6 11 8 16 5 5 -4 9 -2 9 4 0 5 -8 12 -17 14 -17 4
-17 5 0 6 10 1 17 9 17 21 0 17 -7 20 -44 20 -37 0 -45 -4 -49 -20 -4 -17 -4
-16 -3 2 1 22 5 23 95 25 123 3 130 12 9 12 -58 0 -99 -4 -106 -11z"/>
<path d="M772 284 c3 -3 49 -5 102 -5 76 0 97 -3 104 -16 8 -14 10 -14 15 1 7
18 91 23 102 6 5 -8 11 -8 20 0 8 6 37 9 67 7 l53 -3 -55 -2 c-31 -1 -61 -7
-68 -14 -7 -7 -12 -44 -12 -95 0 -93 7 -103 75 -103 61 0 75 14 75 70 l0 50
-40 0 c-33 0 -40 -3 -40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -16 -7
-20 -35 -20 l-35 0 0 65 0 65 35 0 c28 0 35 -4 35 -20 0 -13 7 -20 20 -20 16
0 20 7 21 33 l1 32 8 -30 9 -30 -2 30 c-1 19 -7 30 -17 30 -8 0 -15 7 -15 15
0 19 -100 27 -120 10 -9 -8 -15 -8 -20 0 -8 13 -92 13 -100 0 -5 -7 -11 -8
-19 -1 -12 10 -213 15 -204 5z"/>
<path d="M1591 277 c-6 -8 -12 -39 -13 -71 -3 -62 -2 -63 -50 18 -46 76 -58
64 -58 -59 0 -93 2 -105 18 -106 15 0 14 -2 -3 -10 -18 -8 -19 -9 -1 -6 17 2
19 10 17 92 -1 49 1 86 5 82 5 -4 7 -40 5 -80 -1 -39 0 -69 3 -66 3 3 6 29 7
57 1 29 3 52 5 52 4 0 54 -89 54 -97 0 -4 -11 10 -25 32 -13 22 -24 36 -25 30
0 -17 39 -70 52 -70 7 -1 12 -6 10 -13 -3 -18 26 -26 43 -12 10 9 15 40 17
118 l3 107 130 2 c72 2 140 1 152 -2 30 -7 53 -54 53 -109 0 -25 5 -57 12 -73
6 -15 12 -32 12 -38 1 -12 96 -19 107 -8 4 4 -18 6 -49 5 -31 -2 -55 -2 -52 1
3 3 34 7 68 9 35 1 67 7 72 13 11 14 18 85 8 85 -4 0 -8 -18 -8 -39 0 -22 -4
-43 -10 -46 -6 -4 -8 5 -5 22 2 15 6 44 8 63 l4 35 -41 -3 c-43 -4 -66 -17
-66 -35 0 -6 6 -3 13 7 10 13 23 16 52 13 l40 -4 -42 -1 c-47 -2 -60 -22 -15
-22 26 0 28 -3 24 -30 -4 -29 -6 -30 -53 -30 l-49 0 0 75 0 75 50 0 c43 0 50
-3 50 -20 0 -11 7 -20 15 -20 20 0 19 34 -1 54 -23 23 -110 22 -125 -2 -11
-17 -13 -16 -39 9 -27 28 -28 28 -166 28 -78 0 -143 -4 -150 -10 -8 -7 -14 -7
-19 1 -9 14 -30 13 -44 -3z"/>
<path d="M770 249 c0 -11 -8 -23 -17 -26 -10 -2 -26 -15 -36 -29 -17 -22 -17
-25 -1 -49 9 -14 25 -30 35 -36 11 -5 19 -19 19 -29 0 -17 7 -20 50 -20 43 0
50 3 50 20 0 11 -7 20 -15 20 -12 0 -15 14 -15 65 0 51 3 65 15 65 8 0 15 9
15 20 0 17 -7 20 -50 20 -44 0 -50 -2 -50 -21z"/>
<path d="M880 250 c0 -11 -7 -23 -15 -26 -21 -9 -21 -109 0 -118 8 -3 15 -15
15 -26 0 -17 6 -20 44 -20 49 0 68 16 43 35 -12 8 -17 26 -16 56 l1 44 30 -67
c28 -61 34 -68 59 -68 l29 0 0 78 c0 53 3 72 10 62 8 -13 10 -13 10 2 0 9 -8
19 -17 21 -17 4 -17 5 0 6 10 1 17 9 17 21 0 17 -7 20 -45 20 -38 0 -45 -3
-45 -19 0 -11 8 -22 18 -24 16 -4 16 -5 0 -6 -10 0 -18 -6 -18 -12 0 -5 4 -8
9 -5 7 5 23 -63 16 -72 -1 -1 -14 29 -30 68 l-28 70 -44 0 c-37 0 -43 -3 -43
-20z"/>
<path d="M1310 165 l0 -105 73 -2 72 -2 -70 -6 -70 -7 70 1 69 1 4 88 c2 48 1
99 -2 112 -6 24 -10 25 -76 25 l-70 0 0 -105z"/>
<path d="M1660 165 l0 -105 69 0 c80 0 81 1 81 112 0 97 -1 98 -81 98 l-69 0
0 -105z"/>
<path d="M1820 165 c0 -58 0 -108 -1 -112 0 -5 8 -9 18 -10 15 -2 15 -1 -2 6
-17 8 -18 10 -2 10 14 1 17 10 17 47 l0 45 43 -3 c42 -3 42 -3 45 -45 2 -29 8
-43 18 -43 16 0 17 19 5 71 -5 22 -5 42 1 50 8 11 9 11 5 -1 -2 -8 0 -24 5
-35 5 -13 7 -4 3 29 -8 80 -24 96 -95 96 l-60 0 0 -105z"/>
</g>
`,
]
