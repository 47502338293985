import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const { VITE_SENTRY_ENVIRONMENT: SENTRY_ENVIRONMENT } = import.meta.env;

console.log("import.meta", import.meta);

export const setupSentry = () =>
  Sentry.init({
    dsn: "https://d16d3d25ac134d689de5f495aa03c372@o793342.ingest.sentry.io/5801370",
    integrations: [new Integrations.BrowserTracing()],
    environment: SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
