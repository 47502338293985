import React, { useEffect } from "react";
import { PaperlessComponent } from "../../features/Dashboard/components/PaperlessComponent";
import { trackEvent } from "./../../utils/analytics";
import { getMailingPreference } from "../../User.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const Paperless: React.FC = () => {
  const { loading, activeTenantAccountId } = useAppSelector(
    (state) => state.accountSummary
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ action: "View > Paperless" });
  }, [dispatch]);

  useEffect(() => {
    if (activeTenantAccountId != null) {
      dispatch(getMailingPreference(activeTenantAccountId)).catch(
        (e: string) => {
          throw new Error(e);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTenantAccountId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <PaperlessComponent />;
};

export default Paperless;
