import React, { CSSProperties } from "react";
import iconSecure from "./icon-secure.svg";
import "./style.css";

interface PropsInterface {
  style?: CSSProperties;
}

const SecureDataTransfer: React.FC<PropsInterface> = (props) => {
  const { style } = props;

  return (
    <div className="secureDataTransfer" style={style}>
      <div>
        <img src={iconSecure} alt="" />
        <span>Secure Data Transfer</span>
      </div>
    </div>
  );
};

export default SecureDataTransfer;
