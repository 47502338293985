import React, { useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CButton,
} from "@coreui/react";
import { formatCurrency } from "../../utils/currency";
import { formatDate } from "./../../utils/string";
import { trackEvent } from "./../../utils/analytics";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

const ThankYou: React.FC = () => {
  const { data, activeTenantAccountIndex } = useAppSelector(
    (state) => state.accountSummary
  );
  const paymentMethods = useAppSelector((state) => state.payment.methods.data);
  const accountSummaryData = data?.[activeTenantAccountIndex];
  const accountNumber = accountSummaryData?.accountNumber;
  const serviceAddressConcat = accountSummaryData?.serviceAddressConcat;
  
  const {
    state: {
      lastStatement: { invoiceNumber, billingStartDate, billingEndDate },
      paymentTransaction: { paymentNumber, paymentMethodId, amount },
    },
  } = useLocation() as {
    state: {
      lastStatement: {
        invoiceNumber: string;
        billingStartDate: string;
        billingEndDate: string;
      };
      paymentTransaction: {
        paymentNumber: string;
        paymentMethodId: string;
        amount: string;
      };
    };
  };
  const paymentMethod = paymentMethods.find((pm) => pm.id === paymentMethodId);

  const handlePrint = () => {
    trackEvent({ action: "View > Payment Receipt > Print" });
    const title = document.title;
    document.title = `King Energy Payment ${formatDate().replace(/\//g, "-")}`;
    window.print();
    document.title = title;
  };

  useEffect(() => {
    trackEvent({ action: "View > Payment Receipt" });
  }, []);

  return (
    <div className="flex-grow-1 justify-content-center">
      <CCard>
        <CCardHeader>
          <h3>Payment Receipt</h3>
        </CCardHeader>
        <CCardBody>
          <div style={{ display: "grid", gridGap: 20 }}>
            <div>
              <div>Transaction number:</div>
              <div style={{ fontWeight: 600 }}>{paymentNumber}</div>
            </div>
            <div>
              <div>Submitted:</div>
              <div style={{ fontWeight: 600 }}>
                {new Date().toLocaleString()}
              </div>
            </div>
            <div>
              <div>Amount:</div>
              <div style={{ fontWeight: 600 }}>
                {formatCurrency(Number(amount))}
              </div>
            </div>
            <div>
              <div>Account:</div>
              <div style={{ fontWeight: 600 }}>{paymentMethod?.display}</div>
            </div>
            <div>
              <div>Statement:</div>
              <div style={{ fontWeight: 600 }}>
                {invoiceNumber} ({formatDate(billingStartDate)} -{" "}
                {formatDate(billingEndDate)})
              </div>
            </div>
            <div>
              <div>Account number:</div>
              <div style={{ fontWeight: 600 }}>{accountNumber}</div>
            </div>
            <div>
              <div>Service address:</div>
              <div style={{ fontWeight: 600 }}>{serviceAddressConcat}</div>
            </div>
          </div>
        </CCardBody>
        <CCardFooter className="d-flex">
          <span style={{ flex: 1, fontSize: "1.4em", fontWeight: 500 }}>
            Thank you for your payment.
          </span>
          <CButton onClick={handlePrint} className="noprint">
            Print Payment Receipt
          </CButton>
        </CCardFooter>
      </CCard>
    </div>
  );
};

export default ThankYou;
