import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { formatCurrency } from "../../utils/currency";
import { formatDate } from "./../../utils/string";

interface PropsInterface {
  amount: number;
  paymentMethodDisplay: string;
  resolve: any;
  reject: any;
}

const MakePaymentModal: React.FC<PropsInterface> = (props) => {
  const { amount, paymentMethodDisplay, resolve, reject } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <CModal show onClose={reject}>
      <CModalHeader>
        <strong>Confirm One-Time Payment</strong>
      </CModalHeader>
      <CModalBody>
        <p>
          I authorize King Energy to electronically debit my{" "}
          <strong style={{ whiteSpace: "nowrap" }}>
            {paymentMethodDisplay}
          </strong>{" "}
          account in the amount of <strong>{formatCurrency(amount)}</strong> on{" "}
          <strong>{formatDate()}</strong>, and, if necessary, electronically
          credit my account to correct erroneous debits.
        </p>
        <p>Are you sure you want to make this one-time payment?</p>
      </CModalBody>
      <CModalFooter style={{ display: "flex" }}>
        <CButton
          color="primary"
          style={{ flex: 2 }}
          disabled={isSubmitting}
          onClick={() => {
            setSubmitting(true);
            resolve();
          }}
        >
          Make One-Time Payment
        </CButton>
        <CButton color="secondary" style={{ flex: 1 }} onClick={reject}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default MakePaymentModal;
