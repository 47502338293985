import React from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavItem,
} from "@coreui/react";
import kingLogoExpanded from "..//assets/king-logo-expanded.svg";
import kingLogoCollapsed from "..//assets/king-logo-collapsed.svg";

// sidebar nav config
import { setSidebarShow } from "../App.slice";
import { useMatch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export const TheSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector((state) => state.app.sidebarShow);
  const isImpersonating = window.location !== window.parent.location; // App is loaded in an iframe
  const { logout } = useAuth0();

  return (
    <CSidebar
      // @ts-ignore
      show={show}
      unfoldable
      // @ts-ignore
      onShowChange={(val: boolean) => dispatch(setSidebarShow(val))}
    >
      <CSidebarBrand to="/dashboard">
        <img
          src={kingLogoExpanded}
          className="c-sidebar-brand-full"
          alt=""
          style={{
            width: 190,
            position: "relative",
            left: -15,
          }}
        />
        <img
          src={kingLogoCollapsed}
          className="c-sidebar-brand-minimized"
          alt=""
          style={{ width: 32 }}
        />
      </CSidebarBrand>

      <CSidebarNav>
        <CSidebarNavItem
          name="Energy Dashboard"
          icon="cil-speedometer"
          to="/dashboard"
          color={useMatch("/dashboard") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Make Payment"
          icon="cil-dollar"
          to="/make-payment"
          color={useMatch("/make-payment") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Payment History"
          icon="cil-task"
          to="/payment-history"
          color={useMatch("/payment-history") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="AutoPay"
          icon="cil-loop"
          to="/auto-pay"
          color={useMatch("/auto-pay") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Statements"
          icon="cil-description"
          to="/statements"
          color={useMatch("/statements") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Payment Methods"
          icon="cil-bank"
          to="/payment-methods"
          color={useMatch("/payment-methods") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Documents"
          icon="cil-folder-open"
          to="/documents"
          color={useMatch("/documents") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Paperless Billing"
          icon="cil-leaf"
          to="/paperless"
          color={useMatch("/paperless") != null ? "primary" : undefined}
        />

        <CSidebarNavItem
          name="Account Profile"
          icon="cil-user"
          to="/account-profile"
          color={useMatch("/account-profile") != null ? "primary" : undefined}
        />

        {!isImpersonating && (
          <CSidebarNavItem
            name="Logout"
            icon="cilAccountLogout"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          />
        )}
      </CSidebarNav>
      {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}
    </CSidebar>
  );
};

export default TheSidebar;
