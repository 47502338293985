import React, { useState, useEffect } from "react";
import { PaymentHistoryComponent } from "./components/PaymentHistoryComponent";
import { COLUMNS } from "./columns";
import { PaymentActivityType, addClassToActivitiesForStatus } from "./utils";
import { formatCurrency } from "../../utils/currency";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CFade,
} from "@coreui/react";
import { refreshAccountSummary } from "../../AccountSummary.slice";
import { getPaymentHistory, refreshPaymentHistory } from "./Payment.slice";
import { pushToast } from "../../features/Toaster/Toaster.slice";
import { deletePaymentApi } from "../../apis/apis";
import { trackEvent } from "./../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const PaymentHistory: React.FC = () => {
  const [confirm, setConfirm] = useState<any>(false);
  const { activeTenantAccountId, loading: loadingAS } = useAppSelector(
    (state) => state.accountSummary
  );
  const paymentHistory = useAppSelector((state) => state.payment.history);
  const { loading: loadingPH, data: payments } = paymentHistory;
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ action: "View > Payment History" });
    dispatch(getPaymentHistory()).catch((e: string) => {
      throw new Error(e);
    });
  }, [activeTenantAccountId, dispatch]);

  if (loadingPH || loadingAS) {
    return <div>Loading...</div>;
  }

  const transformedPayments = addClassToActivitiesForStatus(
    payments as PaymentActivityType[]
  );

  const handleCancelPayment = async (paymentId: any) => {
    try {
      await deletePaymentApi(paymentId);
      dispatch(
        pushToast({
          message: "Payment successfully canceled",
          type: "success",
        })
      ).catch((e: string) => {
        throw new Error(e);
      });
      dispatch(refreshPaymentHistory()).catch((e: string) => {
        throw new Error(e);
      });
      dispatch(refreshAccountSummary()).catch((e: string) => {
        throw new Error(e);
      });
    } catch (e: any) {
      console.error(e);
      dispatch(
        pushToast({
          message: `${
            e.response?.data?.message
              ? `${e.response?.data?.message}:`
              : "Error:"
          } ${e.message}`,
          type: "error",
        })
      ).catch((e: string) => {
        throw new Error(e);
      });
    }
  };

  return (
    <>
      <CFade>
        <PaymentHistoryComponent
          columns={COLUMNS}
          activities={transformedPayments}
          handleCancelPayment={(payment: any) => setConfirm(payment)}
        />
      </CFade>

      <CModal show={!!confirm} onClose={() => setConfirm(false)}>
        <CModalHeader>
          <strong>Cancel Payment Confirmation</strong>
        </CModalHeader>
        <CModalBody>
          Are you sure you want to cancel this payment in the amount of{" "}
          <strong>{formatCurrency(confirm.amount)}</strong> from{" "}
          <strong style={{ whiteSpace: "nowrap" }}>
            {confirm.providerName} (••••{confirm.last4})
          </strong>
          ?
        </CModalBody>
        <CModalFooter style={{ display: "flex" }}>
          <CButton
            color="danger"
            style={{ flex: 2 }}
            onClick={() => {
              setConfirm(false);
              handleCancelPayment(confirm.id);
            }}
          >
            Cancel Payment
          </CButton>
          <CButton
            color="secondary"
            style={{ flex: 1 }}
            onClick={() => setConfirm(false)}
          >
            No
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default PaymentHistory;
