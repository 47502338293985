import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getStatementSummaryApi } from '../../apis/apis'

const SLICE_NAME = 'STATEMENT_SUMMARY_SLICE'

export const getStatementSummary = createAsyncThunk(`${SLICE_NAME}/getStatementSummary`, async (_, { rejectWithValue }) => {
  try {
    const response = await getStatementSummaryApi()
    return response
  } catch (err) {
    return rejectWithValue({
      err,
    })
  }
})

interface state {
  loading: boolean
  error: boolean
  data: any
}

const initialState: state = {
  loading: false,
  error: false,
  data: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getStatementSummary.pending, (state: state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getStatementSummary.fulfilled, (state: state, { payload }: any) => {
      state.loading = false
      state.error = false
      state.data = payload
    })
    builder.addCase(getStatementSummary.rejected, (state: state, { payload }: any) => {
      state.loading = false
      state.error = payload
    })
  },
})

export const { reducer: statementSummaryReducer } = slice
