import React, { useState } from "react";
import { pushToast } from "../../Toaster/Toaster.slice";
import { refreshAccountSummary } from "../../../AccountSummary.slice";
import { setPaymentPreferenceApi } from "../../../apis/apis";
import { CSwitch, CButton, CFormGroup, CSelect } from "@coreui/react";
import AddBankAccountModal from "../../PaymentMethods/AddBankAccountModal";
import AutoPayConfirmModal from "./AutoPayConfirmModal";
import { AutoPayDebitDetails } from "./AutoPayDebitDetails";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import AddCreditCardModal from "../../PaymentMethods/AddCreditCardModal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export const AutoPayComponent: React.FC = () => {
  const [autoPayConfirm, setAutoPayConfirm] = useState<any>(false);
  const [cardModal, setCardModal] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const accountSummary = useAppSelector((state) => state.accountSummary);
  const methods = useAppSelector((state) => state.payment.methods);

  const { data: paymentMethods, defaultPaymentMethod } = methods;
  const { data, activeTenantAccountIndex } = accountSummary;

  const dispatch = useAppDispatch();

  const accountSummaryData = data?.[activeTenantAccountIndex];
  const tenantAccountId = accountSummaryData?.id;
  const isAutopayEnabled = accountSummaryData?.autopay?.enabled;

  const toggleBankModal = () => {
    setBankModal(!bankModal);
  };

  const toggleCardModal = () => {
    setCardModal(!cardModal);
  };

  const paymentMethodOptions = paymentMethods.map(
    (pm: any, key: any) =>
      !pm.default && (
        <option value={pm.id} key={`option-${key}`}>
          {pm.display}
        </option>
      )
  );

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">AutoPay</h2>
          <div>
            {isAutopayEnabled && (
              <>
                <p>
                  <strong>You are currently enrolled in AutoPay.</strong>
                </p>
                <p>
                  AutoPay will debit <AutoPayDebitDetails />.
                </p>
                <p>Tap the toggle button below to turn AutoPay off.</p>
                <ul className="list-group">
                  <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                    <span className="font-weight-bold">AutoPay</span>
                    <CSwitch
                      key="auto_pay"
                      color="success"
                      labelOn="On"
                      labelOff="Off"
                      value="success"
                      shape="pill"
                      checked={isAutopayEnabled}
                      onChange={async () => {
                        try {
                          await new Promise((resolve, reject) => {
                            setAutoPayConfirm(
                              <AutoPayConfirmModal
                                resolve={resolve}
                                reject={reject}
                              />
                            );
                          });

                          try {
                            await setPaymentPreferenceApi(
                              tenantAccountId,
                              "autopay",
                              false
                            );
                            dispatch(refreshAccountSummary());
                            setAutoPayConfirm(false);
                            dispatch(
                              pushToast({
                                message: "AutoPay successfully disabled",
                                type: "success",
                              })
                            );
                          } catch (e: any) {
                            console.error(e);
                            dispatch(
                              pushToast({
                                message: `${
                                  e.response?.data?.message
                                    ? `${e.response?.data?.message}:`
                                    : "Error:"
                                } ${e.message}`,
                                type: "error",
                              })
                            );
                          }
                        } catch (e: any) {
                          setAutoPayConfirm(false);
                        }
                      }}
                    />
                  </li>
                </ul>
              </>
            )}

            {!isAutopayEnabled && (
              <>
                <p>
                  <strong>You are not currently enrolled in AutoPay.</strong>
                </p>
                {!paymentMethods.length ? (
                  <>
                    <p>To enable AutoPay, please first add a bank account.</p>
                    <p>
                      <CButton onClick={toggleBankModal}>
                        Add Bank Account
                      </CButton>
                      <CButton
                        onClick={toggleCardModal}
                        style={{ marginLeft: 10 }}
                      >
                        Add Credit Card
                      </CButton>
                    </p>
                  </>
                ) : (
                  <>
                    {Object.keys(defaultPaymentMethod).length === 0 && (
                      <>
                        <p>
                          A payment source must be chosen before turning AutoPay
                          on.
                        </p>
                        <Formik
                          initialValues={{
                            paymentMethodId: "",
                          }}
                          validationSchema={Yup.object().shape({
                            paymentMethodId: Yup.string().required(
                              "Please make a selection"
                            ),
                          })}
                          onSubmit={async (values, { setSubmitting }) => {
                            try {
                              await new Promise((resolve, reject) => {
                                setAutoPayConfirm(
                                  <AutoPayConfirmModal
                                    paymentMethodDisplay={
                                      paymentMethods.filter(
                                        (pm: any) =>
                                          pm.id === values.paymentMethodId
                                      )[0].display
                                    }
                                    resolve={resolve}
                                    reject={reject}
                                  />
                                );
                              });

                              try {
                                await setPaymentPreferenceApi(
                                  tenantAccountId,
                                  "autopay",
                                  true,
                                  values.paymentMethodId
                                );
                                dispatch(refreshAccountSummary());
                                setAutoPayConfirm(false);
                                dispatch(
                                  pushToast({
                                    message: "AutoPay successfully enabled",
                                    type: "success",
                                  })
                                );
                              } catch (e: any) {
                                console.error(e);
                                dispatch(
                                  pushToast({
                                    message: `${
                                      e.response?.data?.message
                                        ? `${e.response?.data?.message}:`
                                        : "Error:"
                                    } ${e.message}`,
                                    type: "error",
                                  })
                                );
                              }
                            } catch (e: any) {
                              setAutoPayConfirm(false);
                              setSubmitting(false);
                              return;
                            }

                            setSubmitting(false);
                          }}
                        >
                          {({
                            // values,
                            errors,
                            touched,
                            // isSubmitting,
                            // isValidating,
                            // isValid,
                            // submitCount,
                            // setFieldValue,
                          }) => (
                            <Form>
                              <CFormGroup
                                className={
                                  errors.paymentMethodId &&
                                  touched.paymentMethodId
                                    ? "error"
                                    : ""
                                }
                              >
                                <Field as={CSelect} name="paymentMethodId">
                                  <option value="">
                                    Select payment source
                                  </option>
                                  {paymentMethodOptions}
                                </Field>
                                <div className="error-text">
                                  {errors.paymentMethodId &&
                                    touched.paymentMethodId &&
                                    errors.paymentMethodId}
                                </div>
                              </CFormGroup>
                              <p>
                                You may add additional payment sources in{" "}
                                <Link to="/payment-methods">
                                  Payment Methods
                                </Link>
                                .
                              </p>
                              <ul className="list-group">
                                <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                                  <span className="font-weight-bold">
                                    AutoPay
                                  </span>
                                  <CSwitch
                                    type="submit"
                                    key="auto_pay"
                                    color="success"
                                    labelOn="On"
                                    labelOff="Off"
                                    value="success"
                                    shape="pill"
                                    checked={isAutopayEnabled}
                                  />
                                </li>
                              </ul>
                            </Form>
                          )}
                        </Formik>
                      </>
                    )}
                    {!(Object.keys(defaultPaymentMethod).length === 0) && (
                      <>
                        <p>
                          Enabling AutoPay will debit <AutoPayDebitDetails />.
                        </p>
                        <p>Tap the toggle button below to turn AutoPay on.</p>
                        <ul className="list-group">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            <span className="font-weight-bold">AutoPay</span>
                            <CSwitch
                              key="auto_pay"
                              color="success"
                              labelOn="On"
                              labelOff="Off"
                              value="success"
                              shape="pill"
                              checked={isAutopayEnabled}
                              onChange={async () => {
                                try {
                                  await new Promise((resolve, reject) => {
                                    setAutoPayConfirm(
                                      <AutoPayConfirmModal
                                        resolve={resolve}
                                        reject={reject}
                                      />
                                    );
                                  });

                                  try {
                                    await setPaymentPreferenceApi(
                                      tenantAccountId,
                                      "autopay",
                                      true,
                                      autoPayConfirm.id
                                    );
                                    dispatch(refreshAccountSummary());
                                    setAutoPayConfirm(false);
                                    dispatch(
                                      pushToast({
                                        message: "AutoPay successfully enabled",
                                        type: "success",
                                      })
                                    );
                                  } catch (e: any) {
                                    console.error(e);
                                    dispatch(
                                      pushToast({
                                        message: `${
                                          e.response?.data?.message
                                            ? `${e.response?.data?.message}:`
                                            : "Error:"
                                        } ${e.message}`,
                                        type: "error",
                                      })
                                    );
                                  }
                                } catch (e: any) {
                                  setAutoPayConfirm(false);
                                }
                              }}
                            />
                          </li>
                        </ul>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {bankModal && (
              <AddBankAccountModal setModal={setBankModal} modal={bankModal} />
            )}
            {cardModal && (
              <AddCreditCardModal setModal={setCardModal} modal={cardModal} />
            )}

            {autoPayConfirm}
          </div>
        </div>
      </div>
    </>
  );
};
