import React, { useEffect, useState } from "react";
import { refreshPaymentMethods } from "./PaymentMethods.slice";
import { pushToast } from "../Toaster/Toaster.slice";
import {
  CInput,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CContainer,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInputCheckbox,
  CAlert,
} from "@coreui/react";
import { Formik, Form, Field } from "formik";
import SecureDataTransfer from "./../../components/SecureDataTransfer/SecureDataTransfer";
import * as Yup from "yup";
import { loadVGSCollect } from "@vgs/collect-js";
import "../../css/collect-vgs.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import axios from "axios";

const {
  VITE_VGS_VAULT_ID: VGS_VAULT_ID,
  VITE_VGS_VAULT_ENVIRONMENT: VGS_VAULT_ENVIRONMENT,
  VITE_VGS_COLLECT_VERSION: VGS_COLLECT_VERSION,
} = import.meta.env;

interface PropsInterface {
  modal: boolean;
  setModal: any;
}

const AddCreditCardModal: React.FC<PropsInterface> = (props) => {
  const { modal, setModal } = props;
  const dispatch = useAppDispatch();
  const paymentMethods = useAppSelector((state) => state.payment.methods.data);
  const requireStr = "This field is required";

  const [vgsValidations, setVgsValidations]: any = useState(null);
  const [validations, setValidations]: any = useState(null);
  const [form, setForm] = useState();
  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const toggle = (visible = !modal) => {
    setModal(visible);
  };

  useEffect(() => {
    if (modal) {
      form && (form as any).reset();
    }
  }, [form, modal]);

  useEffect(() => {
    if (isFormInitialized === true) {
      return;
    }
    setIsFormInitialized(true);
    setVgsValidations(null);
    const loadForm = async () => {
      const vgsCollect = await loadVGSCollect({
        vaultId: VGS_VAULT_ID as string,
        environment: VGS_VAULT_ENVIRONMENT as string,
        version: VGS_COLLECT_VERSION as string,
      }).catch((e) => {
        console.error(e);
      });
      const form = (vgsCollect as any).init((state: any) =>
        setVgsValidations(state)
      );
      const css = {
        "font-size": "14px",
        color: "#40545F",
        "font-family": '"Arial", sans-serif',
        "&::placeholder": {
          color: "#C8D0DB",
        },
        "line-height": "21px",
        "font-weight": "400",
      };
      form.field("#card-number", {
        name: "cardNumberToken",
        type: "card-number",
        placeholder: "0000 0000 0000 0000",
        validations: ["required", "validCardNumber"],
        showCardIcon: "true",
        autoComplete: "cc-number",
        css,
      });
      form.field("#card-expiration-date", {
        name: "expirationDate",
        type: "card-expiration-date",
        placeholder: "MM / YY",
        validations: ["required", "validCardExpirationDate"],
        autoComplete: "cc-exp",
        css,
        serializers: [
          { name: "replace", options: { old: " ", new: "", count: 2 } },
        ],
      });
      form.field("#card-security-code", {
        name: "cvvToken",
        type: "card-security-code",
        placeholder: "CVV",
        validations: ["required", "validCardSecurityCode"],
        autoComplete: "cc-csc",
        css,
      });
      setForm(form);
    };
    loadForm();
  }, [isFormInitialized]);

  useEffect(() => {
    setValidations(validate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vgsValidations]);

  if (!modal) {
    return null;
  }

  const validate = () => {
    if (vgsValidations === null) {
      return;
    }
    const errors: any = {};
    const getValidationState = (name: any) => {
      const validationState: any =
        vgsValidations &&
        Object.values(vgsValidations).filter(
          (item: any) => item.name === name
        )[0];
      return {
        hasError: !validationState?.isValid,
        message: `This field ${validationState?.errorMessages[0]}`,
      };
    };
    ["cardNumberToken", "expirationDate", "cvvToken"].forEach((name: any) => {
      const validationState = getValidationState(name);
      validationState.hasError && (errors[name] = validationState.message);
    });
    return errors;
  };

  const hasVgsError = (name: any, touched: any) =>
    (touched?.[name] || vgsValidations?.[name]?.isTouched) &&
    validations?.[name];

  return (
    <CModal show={modal} onClose={() => toggle(false)} closeOnBackdrop={false}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          cardNumberToken: "",
          expirationDate: "",
          cvvToken: "",
          vaultValues: {},
          primary: true,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(requireStr),
          lastName: Yup.string().required(requireStr),
        })}
        validate={validate}
        onSubmit={(values: any, { setSubmitting }) => {
          (form as any).submit(
            "/api/v1/billing/paymentMethod",
            {
              data: {
                firstName: values.firstName,
                lastName: values.lastName,
                primary: values.primary,
              },
              headers: {
                Authorization: axios.defaults.headers.common["Authorization"],
              },
            },
            async (_status: number) => {
              if (_status !== 204) {
                dispatch(
                  pushToast({
                    message: "Error adding payment method",
                    type: "error",
                  })
                );
                setSubmitting(false);
              } else {
                dispatch(
                  pushToast({
                    message: "Payment method successfully added",
                    type: "success",
                  })
                );
                setSubmitting(false);
                toggle(false);
                dispatch(refreshPaymentMethods()).catch((e: string) => {
                  throw new Error(e);
                });
              }
            }
          );
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          isValidating,
          isValid,
          submitCount,
        }) => (
          <Form>
            <CModalHeader>
              <strong>Add Credit Card</strong>
            </CModalHeader>
            <CModalBody>
              <CContainer fluid>
                <CRow>
                  <CCol sm="12">
                    <CFormGroup
                      className={
                        errors?.firstName && touched?.firstName ? "error" : ""
                      }
                    >
                      <CLabel htmlFor="nf-firstName">First name</CLabel>
                      <Field as={CInput} name="firstName" id="nf-firstName" />
                      <div className="error-text">
                        {errors?.firstName && touched?.firstName
                          ? (errors.firstName as string)
                          : null}
                      </div>
                    </CFormGroup>

                    <CFormGroup
                      className={
                        errors?.lastName && touched?.lastName ? "error" : ""
                      }
                    >
                      <CLabel htmlFor="nf-lastName">Last name</CLabel>
                      <Field as={CInput} name="lastName" id="nf-lastName" />
                      <div className="error-text">
                        {errors?.lastName && touched?.lastName
                          ? (errors.lastName as string)
                          : null}
                      </div>
                    </CFormGroup>

                    <CFormGroup
                      className={
                        hasVgsError("cardNumberToken", touched) ? "error" : ""
                      }
                    >
                      <CLabel htmlFor="card-number">Credit Card Number</CLabel>
                      <div
                        id="card-number"
                        className="form-control field-wrapper vgs-iframe"
                      />
                      <div className="error-text">
                        {hasVgsError("cardNumberToken", touched)}
                      </div>
                    </CFormGroup>

                    <CFormGroup
                      className={
                        hasVgsError("expirationDate", touched) ? "error" : ""
                      }
                    >
                      <CLabel htmlFor="card-expiration-date">
                        Expiration Date
                      </CLabel>
                      <div
                        id="card-expiration-date"
                        className="form-control field-wrapper vgs-iframe"
                      />
                      <div className="error-text">
                        {hasVgsError("expirationDate", touched)}
                      </div>
                    </CFormGroup>

                    <CFormGroup
                      className={
                        hasVgsError("cvvToken", touched) ? "error" : ""
                      }
                    >
                      <CLabel htmlFor="cardSecurityCode">CVV</CLabel>
                      <div
                        id="card-security-code"
                        className="form-control field-wrapper vgs-iframe"
                      />
                      <div className="error-text">
                        {hasVgsError("cvvToken", touched)}
                      </div>
                    </CFormGroup>

                    {!!paymentMethods.length && (
                      <CFormGroup>
                        <CLabel>Primary payment method</CLabel>
                        <div className="form-check">
                          <Field
                            as={CInputCheckbox}
                            type="checkbox"
                            name="primary"
                            id="primary-method"
                          />
                          <CLabel htmlFor="primary-method">
                            Use this account as my primary payment method
                          </CLabel>
                        </div>
                      </CFormGroup>
                    )}
                  </CCol>
                </CRow>
              </CContainer>
              {validations &&
                Object.keys(validations).length > 0 &&
                submitCount > 0 && (
                  <CAlert color="danger">
                    This form contains one or more errors. Please make
                    corrections and try again.
                  </CAlert>
                )}
            </CModalBody>
            <CModalFooter>
              <SecureDataTransfer style={{ flex: 1 }} />
              <CButton
                type="submit"
                color="primary"
                disabled={isSubmitting || (isValidating && !isValid)}
              >
                Add Credit Card
              </CButton>{" "}
              <CButton color="secondary" onClick={() => toggle(false)}>
                Cancel
              </CButton>
            </CModalFooter>
          </Form>
        )}
      </Formik>
    </CModal>
  );
};

export default AddCreditCardModal;
