import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getDocumentsApi } from '../../apis/apis'

const SLICE_NAME = 'DOCUMENTS_SLICE'

interface DocumentsTransformed {
  documentType: string;
  documentLabel: string;
  categoryType: string;
  categoryLabel: string;
  link: string;
}

const transformData = (data: DocumentsResponse): DocumentsTransformed[] => {
  const categoryTypeMap = {
    ONBOARDING_REGISTRATION: "Registration document",
  };
  const documentTypeMap = {
    TESA: "Tenant Energy Services Agreement",
    TESA_SUPPLEMENT: "Utility Agreement",
    PRICING_AND_PAYMENT_TERMS: "Pricing and Payment Terms",
  };
  return Object.entries(data)?.reduce((acc: any[], [catKey, catValue]: any) => {
    return [
      ...acc,
      ...catValue.map((doc: DocumentsTransformed) => ({
        documentType: doc.documentType,
        documentLabel:
          documentTypeMap[doc.documentType as keyof typeof documentTypeMap] ||
          doc.documentType,
        categoryType: catKey,
        categoryLabel:
          categoryTypeMap[catKey as keyof typeof categoryTypeMap] || catKey,
        link: doc.link,
      })),
    ];
  }, []);
};

export const getDocuments = createAsyncThunk(
  `${SLICE_NAME}/getDocuments`,
  async (tenantAccountId: string, { rejectWithValue }) => {
    try {
      const response = await getDocumentsApi(tenantAccountId);
      return transformData(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export interface Documents {
  loading: boolean;
  error: boolean;
  data: Document[] | [];
}

export interface Document {
  documentType: string;
  documentLabel: string;
  categoryType: string;
  categoryLabel: string;
  link: string;
}

export interface DocumentsResponse {
  ONBOARDING_REGISTRATION: OnboardingRegistration[];
}

export interface OnboardingRegistration {
  documentType: "TESA" | "TESA_SUPPLEMENT" | "PRICING_AND_PAYMENT_TERMS";
  link: string;
}

const initialState: Documents = {
  loading: false,
  error: false,
  data: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocuments.pending, (state: Documents) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getDocuments.fulfilled,
      (state: Documents, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload;
      }
    );
    builder.addCase(
      getDocuments.rejected,
      (state: Documents, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { reducer: documentsReducer } = slice
