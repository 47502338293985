import React, { useState, useEffect, useRef } from "react";
import { trackEvent } from "./../../utils/analytics";
import { useLocation } from "react-router-dom";
import { getDocumentApi } from "../../apis/apis";

const Document: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const iframe = useRef<any>(null);
  const {
    state: { link, tenantAccountId },
  } = useLocation();

  useEffect(() => {
    trackEvent({
      action: "View Document",
      value: link,
    });
    (async () => {
      await getDocumentApi(link, tenantAccountId).then((objectUrl) => {
        setLoading(false);
        iframe?.current && (iframe.current.src = objectUrl);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <div style={{ padding: 30 }}>Loading...</div>}
      <iframe
        ref={iframe}
        title="Document"
        style={{ width: "100%", height: "100vh" }}
        frameBorder="0"
      ></iframe>
    </>
  );
};

export default Document;
