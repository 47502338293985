import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPaymentMethodsApi } from "../../apis/apis";
import { trackEvent } from "../../utils/analytics";

const SLICE_NAME = "PAYMENT_METHODS_SLICE";

const transformResponse = (response: PaymentMethod[]) => {
  const responseTransformed = response
    .map((pm) => ({
      ...pm,
      display: `${pm.providerName} (••••${pm.last4})`,
    }))
    .sort((a, b) => {
      if (a.default && !b.default) {
        return -1;
      }
      if (b.default && !a.default) {
        return 1;
      }
      return 0;
    });
  const defaultPaymentMethod =
    responseTransformed.filter((pm) => pm.default)[0] || false;
  return {
    responseTransformed,
    defaultPaymentMethod,
  };
};

export const getPaymentMethods = createAsyncThunk(
  `${SLICE_NAME}/getPaymentMethods`,
  async (_, { rejectWithValue }) => {
    try {
      trackEvent({ action: "API > Payment Methods > List" });
      const response = await getPaymentMethodsApi();
      return transformResponse(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export const refreshPaymentMethods = createAsyncThunk(
  `${SLICE_NAME}/refreshPaymentMethods`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPaymentMethodsApi();
      return transformResponse(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export interface PaymentMethods {
  loading: boolean;
  error: boolean;
  data: PaymentMethod[] | [];
  defaultPaymentMethod: PaymentMethod;
}

export interface PaymentMethod {
  id: string;
  displayName: string;
  providerName: string;
  last4: string;
  type: string;
  status: string;
  default?: boolean;
  display: string;
}

const initialState: PaymentMethods = {
  loading: false,
  error: false,
  data: [],
  defaultPaymentMethod: {
    id: "",
    displayName: "",
    providerName: "",
    last4: "",
    type: "",
    status: "",
    display: "",
  },
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethods.pending, (state: PaymentMethods) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getPaymentMethods.fulfilled,
      (state: PaymentMethods, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload.responseTransformed;
        state.defaultPaymentMethod = payload.defaultPaymentMethod;
      }
    );
    builder.addCase(
      getPaymentMethods.rejected,
      (state: PaymentMethods, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );

    builder.addCase(
      refreshPaymentMethods.fulfilled,
      (state: PaymentMethods, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload.responseTransformed;
        state.defaultPaymentMethod = payload.defaultPaymentMethod;
      }
    );
    builder.addCase(
      refreshPaymentMethods.rejected,
      (state: PaymentMethods, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { reducer: paymentMethodsReducer } = slice;
