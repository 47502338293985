import React, { useEffect, useState, useCallback } from "react";
import { getPaymentMethods } from "./PaymentMethods.slice";
import { PaymentMethodsTable } from "./components/PaymentMethodsTable";
import { COLUMNS } from "./columns";
import { CButton, CFade } from "@coreui/react";
import AddBankAccountModal from "./AddBankAccountModal";
import AddCreditCardModal from "./AddCreditCardModal";
import { trackEvent } from "./../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const PaymentMethods: React.FC = () => {
  const [bankModal, setBankModal] = useState(false);
  const [creditCardModal, setCreditCardModal] = useState(false);

  const { loading: loadingAS } = useAppSelector(
    (state) => state.accountSummary
  );
  const paymentMethods = useAppSelector((state) => state.payment.methods);
  const dispatch = useAppDispatch();
  const getPaymentMethodsCB = useCallback(() => {
    dispatch(getPaymentMethods()).catch((e: string) => {
      throw new Error(e);
    });
  }, [dispatch]);

  useEffect(() => {
    getPaymentMethodsCB();
    trackEvent({ action: "View > Payment Methods" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, loading: loadingPM } = paymentMethods;

  if (loadingPM || loadingAS) {
    return <div>Loading...</div>;
  }

  const toggleBankModal = () => {
    setBankModal(!bankModal);
  };

  const toggleCreditCardModal = () => {
    setCreditCardModal(!creditCardModal);
  };

  return (
    <CFade>
      <div className="flex-grow-1 justify-content-center">
        <div className="card">
          <header className="card-header" style={{ border: 0 }}>
            <h2>Payment Methods</h2>
          </header>
          <PaymentMethodsTable columns={COLUMNS} dataSource={data} />
          <div className="card-footer">
            <CButton onClick={toggleBankModal} color="primary">
              Add Bank Account
            </CButton>
            <CButton
              onClick={toggleCreditCardModal}
              color="primary"
              style={{ marginLeft: 10 }}
            >
              Add Credit Card
            </CButton>
          </div>
        </div>
      </div>
      <AddBankAccountModal setModal={setBankModal} modal={bankModal} />
      {creditCardModal && (
        <AddCreditCardModal
          setModal={setCreditCardModal}
          modal={creditCardModal}
        />
      )}
    </CFade>
  );
};

export default PaymentMethods;
