import React from "react";
import { CToast, CToaster, CToastBody } from "@coreui/react";
import { useAppSelector } from "../../store/hooks";

const Toaster: React.FC = () => {
  const toasts = useAppSelector((state) => state.toaster.data);

  return (
    <CToaster position="bottom-right">
      {toasts.map((toast, key: number) => {
        return (
          <CToast
            key={key}
            show={true}
            autohide={15000}
            fade={true}
            style={{
              color: "white",
              backgroundColor: toast.type === "error" ? "#F03E3E" : "#2eb85c",
            }}
            className="noprint"
          >
            <CToastBody>{toast.message}</CToastBody>
          </CToast>
        );
      })}
    </CToaster>
  );
};

export default Toaster;
