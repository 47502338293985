import React from "react";
import { useLocation } from "react-router-dom";
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CToggler,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CLink,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { setActiveTenantAccountId } from "./../AccountSummary.slice";
import { pushToast } from "./../features/Toaster/Toaster.slice";
import { trackEvent } from "../utils/analytics";
import { setSidebarShow } from "../App.slice";
import kingLogoExpandedBlue from "../assets/king-logo-expanded-blue.svg";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export const TheHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, activeTenantAccountId } = useAppSelector(
    (state) => state.accountSummary
  );
  // const asideShow = useAppSelector((state) => state.app.asideShow)
  // const darkMode = useAppSelector((state) => state.app.darkMode)
  const sidebarShow = useAppSelector((state) => state.app.sidebarShow);
  const user = useAppSelector((state) => state.user);
  const name = user.profile.firstName
    ? `${user.profile.firstName} ${user.profile.lastName}`
    : false;
  const firstInitial = user.profile.firstName?.charAt(0);
  const lastInitial = user.profile.lastName?.charAt(0);
  const initials =
    firstInitial && lastInitial ? `${firstInitial}${lastInitial}` : false;
  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    // @ts-ignore
    dispatch(setSidebarShow(val));
  };
  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    // @ts-ignore
    dispatch(setSidebarShow(val));
  };
  const { pathname } = useLocation();

  const handleChooseTenantAccount = (tenantAccount: any) => {
    dispatch(setActiveTenantAccountId(tenantAccount.id)).catch((e: string) => {
      throw new Error(e);
    });
    trackEvent({ action: "Switch Tenant Account" });
    dispatch(
      pushToast({
        message: `Switched to service account: ${tenantAccount.accountName}`,
        type: "success",
      })
    ).catch((e: string) => {
      throw new Error(e);
    });
  };

  const activeTenantAccount = data.filter(
    (ta: any) => ta.id === activeTenantAccountId
  )[0];
  const tenantAccountOptions = data
    .filter((ta: any) => ta.id !== activeTenantAccountId)
    .map((ta: any, key: any) => (
      <CDropdownItem
        style={{ fontSize: "0.9em" }}
        key={key}
        onClick={() => handleChooseTenantAccount(ta)}
      >
        <span>
          <strong>{ta?.accountName}</strong>{" "}
          {ta?.accountNumber && `(${ta?.accountNumber})`}
          <br />
          {ta?.serviceAddress?.address1}
          {ta?.serviceAddress?.address2 && `, ${ta?.serviceAddress?.address2}`}
        </span>
      </CDropdownItem>
    ));

  const caret = (
    <svg
      width="6px"
      height="29px"
      viewBox="0 0 6 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-495.000000, -444.000000)"
          stroke="#3c4b64"
        >
          <polyline
            id="Path"
            points="495.5 444.5 500.5 458.5 495.5 472.5"
          ></polyline>
        </g>
      </g>
    </svg>
  );

  const accountLabel = (
    <span>
      <strong>{activeTenantAccount?.accountName}</strong>{" "}
      {activeTenantAccount?.accountNumber &&
        `(${activeTenantAccount?.accountNumber})`}
      <br />
      {activeTenantAccount?.serviceAddress?.address1}
      {activeTenantAccount?.serviceAddress?.address2 &&
        `, ${activeTenantAccount?.serviceAddress?.address2}`}
    </span>
  );

  const pathsExcludedFromTenantAccountGroupSelection = [
    "/thank-you",
    "/statement",
    "/document",
  ];

  return (
    <CHeader
      withSubheader
      style={{
        backgroundColor: activeTenantAccount?.sharedAccount
          ? "#fffbea"
          : "white",
      }}
    >
      <div className="d-flex">
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleSidebar}
        />
        <CHeaderBrand className="mx-auto d-lg-none" to="/dashboard">
          <img
            src={kingLogoExpandedBlue}
            alt=""
            style={{ height: 25, marginLeft: 5 }}
          />
        </CHeaderBrand>
      </div>

      <CHeaderNav className="mr-auto">
        {data.length > 1 &&
          !pathsExcludedFromTenantAccountGroupSelection.includes(pathname) && (
            <>
              <span
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.2em",
                  textAlign: "right",
                  fontWeight: 400,
                  marginLeft: 15,
                }}
              >
                Service
                <br />
                Account
              </span>
              <div style={{ margin: "0 10px" }}>{caret}</div>
              <CDropdown>
                <CDropdownToggle
                  style={{ fontSize: "0.9em", textAlign: "left" }}
                >
                  {accountLabel}
                </CDropdownToggle>
                <CDropdownMenu className="account-dropdown">
                  {tenantAccountOptions}
                </CDropdownMenu>
              </CDropdown>
            </>
          )}
        {(data.length === 1 ||
          pathsExcludedFromTenantAccountGroupSelection.includes(pathname)) && (
          <>
            <span
              style={{
                fontSize: "0.9em",
                lineHeight: "1.2em",
                textAlign: "right",
                fontWeight: 400,
                marginLeft: 15,
              }}
            >
              Service
              <br />
              Account
            </span>
            <div style={{ margin: "0 10px" }}>{caret}</div>
            <div style={{ fontSize: "0.9em" }}>{accountLabel}</div>
          </>
        )}
      </CHeaderNav>

      <CHeaderNav className="px-3 d-md-down-none">
        {/* <CToggler
            inHeader
            className="ml-3"
            onClick={() => dispatch(setDarkMode(!darkMode))}
            title="Toggle Light/Dark Mode"
          >
            <CIcon
              name="cil-moon"
              className="c-d-dark-none"
              alt="CoreUI Icons Moon"
            />
            <CIcon
              name="cil-sun"
              className="c-d-default-none"
              alt="CoreUI Icons Sun"
            />
          </CToggler> */}
        <CLink to="/account-profile" className="d-flex text-decoration-none">
          <div className="c-avatar bg-primary text-white mr-2 text-uppercase">
            {initials || <CIcon name="cil-user" />}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {name && (
              <div style={{ fontWeight: 600, fontSize: 14 }}>{name}</div>
            )}
            <div style={{ fontWeight: 400, fontSize: 11 }}>
              {user.data.email}
            </div>
          </div>
        </CLink>
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
        </CSubheader> */}

      {activeTenantAccount?.sharedAccount && (
        <CSubheader
          className="px-3 noprint"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            justifyContent: "center",
            lineHeight: "1.2em",
            fontSize: "0.9em",
            minHeight: 38,
            border: 0,
          }}
        >
          <span>
            This service account is shared and may be managed by one or more
            delegate users.
          </span>
        </CSubheader>
      )}
    </CHeader>
  );
};

export default TheHeader;
