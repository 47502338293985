import React, { useEffect, useState } from "react";
import UserProfileForm from "./ContactDetailsForm";
import { CModal } from "@coreui/react";
import { useAppSelector } from "../../../store/hooks";

const UserProfileModal: React.FC = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { loading, profile } = useAppSelector((state) => state.user);
  const isImpersonating = window.location !== window.parent.location; // App is loaded in an iframe
  const suppressProfileModal =
    JSON.parse(sessionStorage.getItem("suppressProfileModal") ?? "null") ??
    false;

  useEffect(() => {
    if (
      Object.keys(profile).length === 0 &&
      !loading &&
      !isImpersonating &&
      !suppressProfileModal
    ) {
      setModalVisible(true);
    }
  }, [profile, loading, isImpersonating, suppressProfileModal]);

  return (
    <CModal
      show={isModalVisible}
      onClose={() => setModalVisible(false)}
      closeOnBackdrop={false}
    >
      <UserProfileForm setModalVisible={setModalVisible} />
    </CModal>
  );
};

export default UserProfileModal;
