export const ordinalSuffixOf = (value: number | string) => {
  const j = Number(value) % 10
  const k = Number(value) % 100
  if (j === 1 && k !== 11) {
    return `${value}st`
  }
  if (j === 2 && k !== 12) {
    return `${value}nd`
  }
  if (j === 3 && k !== 13) {
    return `${value}rd`
  }
  return `${value}th`
}

export const formatDate = (date: string | Date = new Date()) => {
  if (typeof date === "string" && /\d{4}-\d{2}-\d{2}/.test(date) === false) {
    throw new Error("Date not a valid ISO date string");
  }
  return new Date(date).toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  });
};

export const formatUTCDate = (date: string | Date = new Date()) => {
  if (typeof date === "string" && /\d{4}-\d{2}-\d{2}/.test(date) === false) {
    throw new Error("Date not a valid ISO date string");
  }
  const d = new Date(date);
  return `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${String(
    d.getUTCFullYear()
  ).substring(2)}`;
};

export const formatPhoneNumber = (value = '') => {
  const numericValue = value?.replace('+1', '')?.replace(/\D/g, '')
  const match = numericValue.match(/^(\d{3})(\d{3})(\d{4})/)
  return match !== null ? `(${match[1]}) ${match[2]}-${match[3]}` : value
}

export const formatPostalCode = (value = '') => {
  const numericValue = value.replace(/\D/g, '')
  const match2 = numericValue.match(/^(\d{5})(\d{4})/)
  const match1 = numericValue.match(/^(\d{5})/)
  return match2 != null ? `${match2[1]}-${match2[2]}` : match1 != null ? match1[1] : value
}

export const usStates = {
  '': '',
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}
