import React from "react";
import { setUserProfileApi } from "../../../apis/apis";
import { refreshUserProfile } from "../../../User.slice";
import { pushToast } from "../../Toaster/Toaster.slice";
import {
  CInput,
  CButton,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CContainer,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CLink,
} from "@coreui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { formatPhoneNumber } from "../../../utils/string";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

interface PropsInterface {
  setModalVisible?: (visible: boolean) => void;
}

const ContactDetailsForm: React.FC<PropsInterface> = (props) => {
  const dispatch = useAppDispatch();
  const { setModalVisible } = props;
  const { loading, profile, data } = useAppSelector((state) => state.user);
  const requireStr = "This field is required";

  if (loading) {
    return (
      <>
        <CModalHeader>
          <div>
            <h3>Contact Details</h3>
          </div>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm="12">Loading...</CCol>
            </CRow>
          </CContainer>
        </CModalBody>
      </>
    );
  }

  return (
    <Formik
      initialValues={{
        firstName: profile?.firstName || "",
        lastName: profile?.lastName || "",
        phoneNumber: formatPhoneNumber(profile?.phoneNumber) || "",
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        firstName: Yup.string().trim().required(requireStr),
        lastName: Yup.string().trim().required(requireStr),
        phoneNumber: Yup.string()
          .trim()
          .test(
            "phone",
            "Phone number must contain 10 digits",
            function (value?: string | null) {
              return value?.replace(/\D/g, "").match(/^\d{10}$/) ? true : false;
            }
          )
          .required(requireStr),
      })}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          try {
            await setUserProfileApi({
              ...values,
              phoneNumber: values.phoneNumber,
            });
            dispatch(refreshUserProfile());
            dispatch(
              pushToast({
                message: `Contact details updated`,
                type: "success",
              })
            );
            sessionStorage.setItem("suppressProfileModal", "true");
            setSubmitting(false);
            setModalVisible?.(false);
          } catch (e: any) {
            dispatch(
              pushToast({
                message: `${
                  e.response?.data?.message
                    ? `${e.response?.data?.message}:`
                    : "Error:"
                } ${e.message}`,
                type: "error",
              })
            );
            sessionStorage.setItem("suppressProfileModal", "true");
            setSubmitting(false);
            setModalVisible?.(false);
          }
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValidating,
        isValid,
        setFieldValue,
        handleBlur,
        validateField,
      }) => (
        <Form>
          <CModalHeader>
            <div>
              <h3>Welcome to OneBill™</h3>
              {!profile && (
                <p>
                  Please help us personalize your account by providing us with a
                  few details about yourself.
                </p>
              )}
            </div>
          </CModalHeader>
          <CModalBody>
            <CContainer fluid>
              <CFormGroup
                className={
                  errors?.firstName && touched?.firstName ? "error" : ""
                }
              >
                <CLabel htmlFor="nf-firstName">First name</CLabel>
                <Field
                  as={CInput}
                  name="firstName"
                  id="nf-firstName"
                  onBlur={async (event: any) => {
                    await setFieldValue(
                      "firstName",
                      String(values.firstName.charAt(0).toUpperCase()) +
                        String(values.firstName.slice(1))
                    );
                    handleBlur(event);
                  }}
                />
                <div className="error-text">
                  {errors?.firstName && touched?.firstName
                    ? (errors.firstName as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={errors?.lastName && touched?.lastName ? "error" : ""}
              >
                <CLabel htmlFor="nf-lastName">Last name</CLabel>
                <Field
                  as={CInput}
                  name="lastName"
                  id="nf-lastName"
                  onBlur={async (event: any) => {
                    await setFieldValue(
                      "lastName",
                      String(values.lastName.charAt(0).toUpperCase()) +
                        String(values.lastName.slice(1))
                    );
                    handleBlur(event);
                  }}
                />
                <div className="error-text">
                  {errors?.lastName && touched?.lastName
                    ? (errors.lastName as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={
                  errors?.phoneNumber && touched?.phoneNumber ? "error" : ""
                }
              >
                <CLabel htmlFor="nf-phoneNumber">Phone number</CLabel>
                <Field
                  as={CInput}
                  name="phoneNumber"
                  id="nf-phoneNumber"
                  onBlur={async (event: any) => {
                    await setFieldValue(
                      "phoneNumber",
                      formatPhoneNumber(values.phoneNumber)
                    );
                    handleBlur(event);
                    setTimeout(async () => {
                      await validateField("phoneNumber");
                    }, 0);
                  }}
                />
                <div className="error-text">
                  {errors?.phoneNumber && touched?.phoneNumber
                    ? errors.phoneNumber
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup>
                <CLabel htmlFor="nf-phoneNumber">Email address</CLabel>
                <CInput type="text" value={data?.email} disabled />
              </CFormGroup>

              <div>
                If you need to change your email address,
                <span className="mr-1">
                  {" "}
                  please give us a call at{" "}
                  <CLink href="tel://8007811765" tabIndex={-1}>
                    (800) 781-1765
                  </CLink>{" "}
                  or email us at{" "}
                  <CLink href="mailto:support@kingenergy.com" tabIndex={-1}>
                    support@kingenergy.com
                  </CLink>
                </span>
              </div>
            </CContainer>
          </CModalBody>
          <CModalFooter className="justify-content-start">
            <CButton
              type="submit"
              color="primary"
              disabled={isSubmitting || (isValidating && !isValid)}
            >
              Update Contact Details
            </CButton>{" "}
            <CButton
              color="primary"
              onClick={() => {
                sessionStorage.setItem("suppressProfileModal", "true");
                setModalVisible?.(false);
              }}
              variant="ghost"
            >
              Maybe Later
            </CButton>
          </CModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ContactDetailsForm;
