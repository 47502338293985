interface Heap {
  track: (event: string, properties?: object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: object) => void;
  addEventProperties: (properties: object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: unknown;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

export const trackEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category?: string | null;
  label?: string | null;
  value?: string | null;
}) => {
  window.heap.track(action, { category, label, value });
};

export const setPersistentValues = ({
  name,
  email,
  tenantAccountGroupId,
}: {
  name?: string | null;
  email?: string | null;
  tenantAccountGroupId?: string | null;
}): void => {
  window.heap.identify(email as string);
  window.heap.addUserProperties({
    name,
    email,
    tenantAccountGroupId,
  });
};
