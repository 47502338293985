import React, { useEffect } from "react";
import { setPreferenceApi } from "../../../apis/apis";
import { pushToast } from "../../Toaster/Toaster.slice";
import { getMailingPreference } from "../../../User.slice";
import {
  CInput,
  CSelect,
  CButton,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CContainer,
  CFormGroup,
  CLabel,
} from "@coreui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { usStates, formatPostalCode } from "../../../utils/string";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const MailingPreferenceForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector(
    (state) => state.user.mailingPreference
  );
  const {
    data: asData,
    activeTenantAccountIndex,
    activeTenantAccountId,
  } = useAppSelector((state) => state.accountSummary);

  useEffect(() => {
    if (activeTenantAccountId) {
      dispatch(getMailingPreference(activeTenantAccountId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTenantAccountId]);

  if (loading || !data) {
    return (
      <>
        <CModalHeader>
          <div>
            <h3>Mailing Address</h3>
          </div>
        </CModalHeader>
        <CModalBody>
          <div>Loading...</div>
        </CModalBody>
      </>
    );
  }

  const accountSummaryData = asData?.[activeTenantAccountIndex];
  const paperless = accountSummaryData?.paperless;

  const {
    recipientName,
    mailingAddress: { address1, address2, city, state, zip } = {} as any,
  } = data;
  const requireStr = "This field is required";

  return (
    <Formik
      initialValues={{
        recipientName: recipientName || "",
        street1: address1 || "",
        street2: address2 || "",
        city: city || "",
        state: state || "",
        zip: zip || "",
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        recipientName: Yup.string().trim().required(requireStr),
        street1: Yup.string().trim().required(requireStr),
        city: Yup.string().trim().required(requireStr),
        state: Yup.string().trim().required(requireStr),
        zip: Yup.string()
          .trim()
          .required(requireStr)
          .test("range", "5 or 9 digits required", (value) => {
            return (
              value?.replace(/\D/g, "").length === 5 ||
              value?.replace(/\D/g, "").length === 9
            );
          })
          .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid zip code format"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          try {
            await setPreferenceApi(activeTenantAccountId, "paperless", values);
            dispatch(
              pushToast({
                message: `Mailing address updated`,
                type: "success",
              })
            );
            setSubmitting(false);
          } catch (e: any) {
            dispatch(
              pushToast({
                message: `${
                  e.response?.data?.message
                    ? `${e.response?.data?.message}:`
                    : "Error:"
                } ${e.message}`,
                type: "error",
              })
            );
            setSubmitting(false);
          }
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValidating,
        isValid,
        setFieldValue,
        handleBlur,
        validateField,
      }) => (
        <Form>
          <CModalHeader>
            <div>
              <h3>Mailing Address</h3>
            </div>
          </CModalHeader>
          <CModalBody>
            <CContainer fluid>
              <CFormGroup
                className={
                  errors?.recipientName && touched?.recipientName ? "error" : ""
                }
              >
                <CLabel htmlFor="nf-recipientName">Recipient name</CLabel>
                <Field as={CInput} name="recipientName" id="nf-recipientName" />
                <div className="error-text">
                  {errors?.recipientName && touched?.recipientName
                    ? (errors.recipientName as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={errors?.street1 && touched?.street1 ? "error" : ""}
              >
                <CLabel htmlFor="nf-street1">Address line 1</CLabel>
                <Field as={CInput} name="street1" id="nf-street1" />
                <div className="error-text">
                  {errors?.street1 && touched?.street1
                    ? (errors.street1 as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={errors?.street2 && touched?.street2 ? "error" : ""}
              >
                <CLabel htmlFor="nf-street2">Address line 2</CLabel>
                <Field as={CInput} name="street2" id="nf-street2" />
                <div className="error-text">
                  {errors?.street2 && touched?.street2
                    ? (errors.street2 as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={errors?.city && touched?.city ? "error" : ""}
              >
                <CLabel htmlFor="nf-city">City</CLabel>
                <Field as={CInput} name="city" id="nf-city" />
                <div className="error-text">
                  {errors?.city && touched?.city
                    ? (errors.city as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={errors?.state && touched?.state ? "error" : ""}
              >
                <CLabel htmlFor="nf-state">State</CLabel>
                <Field as={CSelect} name="state" id="nf-state">
                  {Object.entries(usStates).map((item) => (
                    <option value={item[0]} key={item[0]}>
                      {item[1]}
                    </option>
                  ))}
                </Field>
                <div className="error-text">
                  {errors?.state && touched?.state
                    ? (errors.state as string)
                    : null}
                </div>
              </CFormGroup>

              <CFormGroup
                className={errors?.zip && touched?.zip ? "error" : ""}
              >
                <CLabel htmlFor="nf-zip">Zip code</CLabel>
                <Field
                  as={CInput}
                  name="zip"
                  id="nf-zip"
                  onBlur={(event: any) => {
                    setFieldValue("zip", formatPostalCode(values.zip));
                    handleBlur(event);
                    setTimeout(() => {
                      validateField("zip");
                    }, 0);
                  }}
                />
                <div className="error-text">
                  {errors?.zip && touched?.zip ? (errors.zip as string) : null}
                </div>
              </CFormGroup>

              {!paperless && (
                <p>
                  This mailing address will be used for statement
                  correspondence.
                </p>
              )}

              <p>
                {paperless ? (
                  <>
                    You are <strong>currently enrolled</strong> in paperless
                    e-statements.
                  </>
                ) : (
                  <>
                    You are <strong>not</strong> currently enrolled in paperless
                    e-statements.
                  </>
                )}{" "}
              </p>

              <div>
                <Link to="/paperless" tabIndex={-1}>
                  <CButton tabIndex={-1}>Manage paperless preference</CButton>
                </Link>
              </div>
            </CContainer>
          </CModalBody>
          <CModalFooter className="justify-content-start">
            <CButton
              type="submit"
              color="primary"
              disabled={isSubmitting || (isValidating && !isValid)}
            >
              Update Mailing Address
            </CButton>{" "}
          </CModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default MailingPreferenceForm;
