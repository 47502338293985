import React, { useCallback, useEffect, useState } from "react";
import { setPersistentValues, trackEvent } from "../utils/analytics";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserData, setUser } from "../User.slice";
import { useAppDispatch } from "../store/hooks";
import qs from "qs";
import * as Sentry from "@sentry/react";
import LinkExpired from "../errors/LinkExpired";
import AccountLinkingFailed from "../errors/AccountLinkingFailed";

const RegisterAccount: React.FC = () => {
  const [isLinking, setLinking] = useState(false);
  const [linkingFailure, setLinkingFailure] = useState(false);
  // const [ testTenantAccountGroupId, setTestTenantAccountGroupId ] = useState<any>(null); // For testing
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    getAccessTokenSilently,
    user,
    isLoading,
    loginWithRedirect,
    isAuthenticated,
  } = useAuth0();
  const { invitationCode, message: auth0QueryParamMessage } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const dispatch = useAppDispatch();

  const tenantAccountGroupId = user?.[
    "https://account.kingenergy.com/tenantAccountGroupId"
  ] as string;
  const case2linking =
    isAuthenticated && Boolean(invitationCode) && !tenantAccountGroupId;
  const case3previouslyLinked =
    isAuthenticated && Boolean(tenantAccountGroupId);

  const getAccessTokenSilentlyCB = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      cacheMode: "off",
    });
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return accessToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  const linkAccount = useCallback(async () => {
    if (isLinking) {
      return;
    }
    setLinking(true);

    try {
      await getAccessTokenSilentlyCB();
      // console.log("Access Token Init", accessTokenInit);
    } catch (e) {
      console.error("Access Token Error - Init", e);
    }
    trackEvent({ action: "Auth > Link > Start" });

    try {
      await axios.post("/api/v1/billing/auth/link", {
        inviteToken: invitationCode,
      });
      trackEvent({ action: "Auth > Link > Success" });
      // setTestTenantAccountGroupId("ckrgpp9hb0000elo27tun7li6"); // For testing
    } catch (e) {
      console.error("Linking error", e);
      trackEvent({
        action: "Auth > Link > Error",
        category: "error",
        value: e as string,
      });
      setLinkingFailure(true);
      return;
    }

    try {
      await getAccessTokenSilentlyCB();
      // console.log("Access Token Post-Link", accessTokenPostLink);
      dispatch(setUser(user as unknown as UserData));
      navigate("/dashboard", { replace: true });
    } catch (e) {
      setLinkingFailure(true);
      console.error("Access Token Error - Post Link", e);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [case2linking]);

  useEffect(() => {
    (async () => {
      if (!isLoading && !isAuthenticated) {
        trackEvent({ action: "Auth > Register > Start" });
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
            redirect_uri: `${window.location.origin}/?invitationCode=${
              invitationCode as string
            }`,
          },
        }).catch((e: string) => {
          throw new Error(e);
        });
      }

      if (!isLoading && isAuthenticated) {
        await linkAccount();
      }
    })().catch((e: string) => {
      throw new Error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  useEffect((): void => {
    if (isLoading) {
      return;
    }
    (async () => {
      try {
        await getAccessTokenSilentlyCB();
        // console.log("Access Token Init", accessTokenInit);
      } catch (e) {
        console.error("Access Token Error - Init", e);
      }

      // console.log("metadata", {
      //   user,
      //   // userTest, // For testing
      //   invitationCode,
      //   tenantAccountGroupId,
      //   isAuthenticated,
      //   isLoading,
      //   case1notLinked,
      //   case2linking,
      //   case3previouslyLinked,
      // });
      // console.log("-------------");

      setPersistentValues({
        tenantAccountGroupId,
        name: user?.name,
        email: user?.email,
      });
    })().catch((e: string) => {
      throw new Error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantAccountGroupId, case2linking, case3previouslyLinked]);

  useEffect(() => {
    if (case3previouslyLinked) {
      // console.log("Case 3 - Previously Linked");
      trackEvent({ action: "Auth > Previously Linked" });
      dispatch(setUser(user as unknown as UserData));
      Sentry.setUser({
        email: user?.email,
        tenantAccountGroupId,
      });
      Sentry.setContext("User", {
        email: user?.email,
        tenantAccountGroupId,
      });
      if (window.location.pathname === "/") {
        navigate("/dashboard", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [case2linking]);

  if (auth0QueryParamMessage) {
    trackEvent({ action: "Auth > Link Expired" });
    return <LinkExpired />;
  }

  if (!invitationCode) {
    return <div style={{ margin: 20 }}>Error: Invitation code is missing</div>;
  }

  if (linkingFailure) {
    return <AccountLinkingFailed />;
  }

  if (isAuthenticated && !tenantAccountGroupId) {
    return (
      <div style={{ margin: 20 }}>Error: Account not assigned to group</div>
    );
  }

  if (isAuthenticated && invitationCode) {
    return <div style={{ margin: 20 }}>Linking your account...</div>;
  }

  return <div style={{ margin: 20 }}>Loading...</div>;
};

export default RegisterAccount;
