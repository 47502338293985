import React, { Suspense, useEffect } from "react";
import { CContainer, CFade } from "@coreui/react";
import { getAccountSummary } from "../AccountSummary.slice";
import { getUserProfile } from "../User.slice";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

interface PropsInterface {
  children: React.ReactNode;
}

export const TheContent: React.FC<PropsInterface> = ({ children }) => {
  const dispatch = useAppDispatch();
  const accountSummary = useAppSelector((state) => state.accountSummary);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (accountSummary?.data?.length === 0) {
      dispatch(getAccountSummary()).catch((e: string) => {
        throw new Error(e);
      });
    }
    if (user?.profile?.firstName === "") {
      dispatch(getUserProfile()).catch((e: string) => {
        throw new Error(e);
      });
    }
  }, [dispatch, user?.profile, accountSummary?.data]);

  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <CFade>{children}</CFade>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default TheContent;
